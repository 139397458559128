<div class="conteneur" *ngIf="preinscriptions; else loader">

  <mat-card *ngIf="config && config.infoMessageList" style="margin: 5px;"
    [portailCustomStyle]="config.styleCardInfoList">
    <div [innerHtml]="config.infoMessageList | safe"></div>
  </mat-card>

  <div class="preinscription-list">

    <mat-card class="preinscription-card" *ngFor="let preinscription of preinscriptions">
      <mat-card-header class="card-header fx-direction-row fx-between-center">
        <mat-card-title class="fx-direction-row fx-start-center">
          <mat-icon *ngIf="preinscription.idAdulte" style="margin-right: 5px;">person</mat-icon>
          <app-icon *ngIf="preinscription.idEnfant" style="margin-right: 5px;" type="do" icon="enfant"></app-icon>
          <span>{{ preinscription.nom }} {{ preinscription.prenom }}</span>
        </mat-card-title>
        <button *ngIf="permToEdit" mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-header>

      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="onClickEdit(preinscription)">
          <mat-icon color="primary">edit
          </mat-icon>
          {{ 'action.see_edit'|trans }}
        </button>
      </mat-menu>

      <mat-card-content>
        <div>
          <label class="label">{{ 'lasido.annee_scolaire'|trans }} : </label>
          <span>{{ preinscription.anneeScolaireName }}</span>
        </div>

        <div *ngIf="preinscription.accueilName">
          <label class="label">Etablissement - Accueil :</label>
          <span *ngIf="preinscription.etablissementName">{{ preinscription.etablissementName }} ></span>
          {{ preinscription.accueilName }}
        </div>

        <div>
          <label class="label">{{ 'lasido.date_demande'|trans }} : </label>
          <span>{{ preinscription.dateDemande ? (preinscription.dateDemande|date) : '' }}</span>
        </div>

        <div class="status" style="place-items: center;">
          <label class="label">{{ 'lasido.status'|trans }} : </label>
          <span>
            {{ preinscription.decision }}

            <app-icon *ngIf="preinscription.decision === 'Refusée'" type="fa" icon="times-circle"
              class="icon-decision warn"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Partiellement acceptée'" type="fa" icon="check-circle"
              class="icon-decision accent"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Acceptée'" type="fa" icon="check-circle"
              class="icon-decision primary"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Abandon'" type="fa" icon="trash" class="icon-decision">
            </app-icon>
            <app-icon *ngIf="preinscription.decision === 'En attente'" type="fa" icon="question-circle"
              class="icon-decision accent"></app-icon>
          </span>
        </div>

        <hr>

        <div class="choices-list-container">
          <label class="label">{{ 'lasido.souhaits'|trans }} : </label>
          <ol *ngIf="preinscription.choiceGroups?.length" class="list-souhaits">
            <li *ngFor="let choice of preinscription.choiceGroups" style="border-radius: 6px;
            padding: 10px; margin:10px;" class="border item-souhait">

              <div class="fx-direction-row fx-between-center">
                <!-- <app-icon style="margin-right: 5px;" type="do" icon="departement"></app-icon> -->
                <div style="font-weight: 500;font-size: 15px;">
                  <span *ngIf="choice.departementName">
                    {{ choice.departementName }}<span class="separator">-</span>
                  </span>
                  <span>{{ choice.disciplineName }}<span class="separator">-</span></span>
                  <span>{{ choice.niveauName }}</span>
                </div>
                <app-icon *ngIf="choice.decision === 'Refusée'" type="fa" icon="times-circle" class="icon-decision warn"
                  matTooltip="Refusée"></app-icon>
                <app-icon *ngIf="choice.decision === 'Acceptée'" type="fa" icon="check-circle"
                  class="icon-decision primary" matTooltip="Acceptée"></app-icon>
                <app-icon *ngIf="choice.decision === 'Abandon'" type="fa" icon="trash" class="icon-decision"
                  matTooltip="Abandon">
                </app-icon>
                <app-icon *ngIf="choice.decision === 'En attente' || choice.decision === ''" type="fa"
                  icon="question-circle" class="icon-decision accent" matTooltip="En attente"></app-icon>
              </div>

              <ol *ngIf="choice.cours?.length" class="courses-list">
                <li *ngFor="let cours of choice.cours; let i = index" style="
                padding: 8px; border-radius: 6px; margin-top: 10px;" class="border item-cours"
                  [ngClass]="{'primary-lighter-bg': cours.isCoursRetenu}">
                  <div class="title-cours">
                    <span class="course-name">
                      {{ cours.name }}
                      <mat-icon *ngIf="cours.isCoursRetenu" color="primary" matTooltip="Cours retenu">
                        check_circle
                      </mat-icon>
                    </span>
                  </div>

                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Horaire : </label>
                    <span *ngIf="!cours.provisoire; else coursProvisoire">Le {{cours.jour}}
                      de
                      {{cours.heureDebut}} à
                      {{cours.heureFin}}</span>
                  </div>
                  <ng-template #coursProvisoire>
                    <span>{{'lasido.text-cours-provisoire' | trans}}</span>
                  </ng-template>

                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Enseignant(s) : </label>
                    <span *ngFor="let enseignant of cours.enseignants; let last = last" style="margin-right: 5px;">
                      {{enseignant.nomPrenom}}{{!last ? ' - ' : ''}}
                    </span>
                  </div>
                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Type : </label>
                    <span>{{cours.individuel ? 'Individuel' : 'Collectif'}}</span>
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card *ngIf="permToCreate" class="actions-card card-add">
    <mat-card-header class="card-header fx-direction-row fx-center-center">
      <mat-card-title class="fx-direction-row fx-center-center">
        {{ 'lasido.new_preinscription'|trans }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-top: 20px;" class="fx-direction-row fx-center-center">
      <button mat-fab color="primary" matTooltip="{{ 'lasido.new_preinscription'|trans }}" [disabled]="!addBtnUrl"
        [routerLink]="addBtnUrl">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!preinscriptions.length && !permToCreate">
    <h2 style="text-align: center;">Vous n'avez pas de pré-inscription(s).</h2>
  </div>

</div>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>