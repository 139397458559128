import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions, ExtendedFormlyTemplateOptions } from '@app/forms/config/form-model';
import { AuthenticationService, FormConfigService, PlatformService } from '@app/services';
import { accessProperty, clone } from '@app/utils/object';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SuggestionDialogComponent } from './suggestion-dialog/suggestion-dialog.component';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-formly-suggestion',
  templateUrl: './suggestion-type.component.html',
  styleUrls: ['./suggestion-type.component.scss']
})
export class SuggestionTypeComponent extends FieldType implements OnInit, OnDestroy {

  @ViewChild('suggestionDialog', { static: true }) suggestionDialogTemplate: TemplateRef<any>;

  field: ExtendedFormlyFieldConfig;
  options: ExtendedFormlyFormOptions;
  to: ExtendedFormlyTemplateOptions;

  suggestedItems: any[];
  selection: any;
  isNew: boolean;
  isMobile: boolean;

  private onDestroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public platformService: PlatformService,
    protected http: HttpClient,
    private authenticationService: AuthenticationService,
    private formConfigService: FormConfigService
  ) {
    super();
  }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((value) => {
      this.isMobile = value;
    });

    if (this.field.formControl.value["id"] === null && (this.field.key === "Bloc_enfant" || this.field.key === "Bloc_famille")) {
      this.isNew = true;
    }

    if (!this.options.formState.disabled) {
      this.suggestedItems = [];
      if (this.to.suggestedItemsUrl) {

        // For sharing ressource from 'suggestedItemsUrl' by options.formState
        this.options.formState.suggestions = this.options.formState.suggestions || {};

        if (!this.options.formState.suggestions[this.to.suggestedItemsUrl]) {

          this.options.formState.suggestions[this.to.suggestedItemsUrl] = new BehaviorSubject([]);

          if (!this.options.adminMode) {
            const idAssmat: string = this.authenticationService.currentUserValue.idAssmat.toString();
            const url = this.to.suggestedItemsUrl.replace('#idAssmat#', idAssmat);

            this.http.get<any[]>(url).subscribe(result => {
              this.options.formState.suggestions[this.to.suggestedItemsUrl].next(result);
            });
          } else {
            // On peut mettre des exemples pour la page admin dans defaultValue.
            this.options.formState.suggestions[this.to.suggestedItemsUrl].next(clone(this.field.defaultValue));
          }
        }

        this.options.formState.suggestions[this.to.suggestedItemsUrl]
          .subscribe(suggestions => {
            this.suggestedItems = suggestions;
          });
      }
    }

    if (!this.options.adminMode) {
      this.field.defaultValue = [];
    } else {
      this.suggestedItems = [this.suggestedItems]
    }

  }

  isNullish(obj): boolean {

    if (obj === undefined) {
      return true;
    }

    return Object.values(obj).every(value => {
      return value === null ? true : false
    });
  }

  getColValue(item, colDefKey) {
    if (item.model) {
      let value = accessProperty(item.model, colDefKey);

      const field = this.formConfigService.findFieldByName(item.fieldGroup, colDefKey);

      if (field && field.type === 'select' && field.templateOptions && field.templateOptions.options) {
        const options = (field.templateOptions.options as Array<any>);

        value = options.find(opt => opt.value === value) ? options.find(opt => opt.value === value).label : '';
      }

      if (colDefKey === "dateNaissance") {
        if (value) {
          value = new Date(value).toLocaleDateString();
        }
      }
      return value;
    }
  }

  onAddClick() {
    this.openSuggestionDialog();
  }

  openEditDialog(item): void {
    let title = 'Modifier ' + this.to.itemLabel;
    if (item === null) {
      item = { fieldGroup: clone(this.field.fieldGroup), model: {}, formControl: {} };
      title = 'Ajouter ' + this.to.itemLabel;
    }

    const data = {
      field: item,
      options: this.options,
      title,
      readOnly: this.field.readOnly
    };

    this.openDialog(SuggestionDialogComponent, data, (result => {
      if (result) {
        if (!result.id) {
          this.field.formControl.reset();
          result.tmpId = new Date().getTime();
        }
        this.applyModelToFormValue(result);
      }
    }))
  }

  openSuggestionDialog() {
    this.openDialog(this.suggestionDialogTemplate, {});
  }

  validateSelection() {
    this.applyModelToFormValue(this.selection);
  }

  applyModelToFormValue(data) {
    this.field.formControl.patchValue(data);
    this.form.controls[this.field.key as string].markAsTouched();
    this.updateSuggestions(data);
  }

  openDialog(componentOrTemplateRef, data, callback?): void {

    const dialConf: MatDialogConfig = {
      data,
      closeOnNavigation: true,
      maxWidth: '750px'
    };

    if (!this.options.adminMode && this.isMobile) {
      dialConf.panelClass = 'full-screen-dialog';
    }

    if (this.options.adminMode && this.options.containerWidth <= 400) {
      dialConf.panelClass = 'full-screen-dialog-admin-mode';
      dialConf.position = { right: '0' };
    }

    const dialogRef = this.dialog.open(componentOrTemplateRef, dialConf);
    dialogRef.afterClosed().subscribe(callback);
  }

  updateSuggestions(data) {

    const index = this.suggestedItems.findIndex(item => item.id === data.id);

    let suggestions = clone(this.suggestedItems);

    if (index !== -1) {
      suggestions[index] = data;
    }

    if (this.to.suggestedItemsUrl) {
      this.options.formState.suggestions[this.to.suggestedItemsUrl].next(suggestions);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
