import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigDiabolo, DiaboloInsc, ParamLibre } from '@app/models/diabolo';
import { FamilyService, FormHelperService, PlatformService, SnackbarService } from '@app/services';
import { DiaboloService } from '@app/services/diabolo.service';
import { HeaderService } from '@app/services/header.service';
import { distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { Usager } from '@app/models/consumer';
import moment from 'moment';
import { PeriodeTreeItem } from '@app/models/periode';
import { NestedTreeControl } from '@angular/cdk/tree';
import { PeriodeService } from '@app/services/periode.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { ProgrammePersoService } from '@app/services/programme-perso.service';
import { DemarcheFormReponse } from '@app/models/demarche';

@Component({
  selector: 'app-edit-diabolo-inscriptions',
  templateUrl: './edit-diabolo-inscriptions.component.html',
  styleUrls: ['./edit-diabolo-inscriptions.component.scss']
})
export class EditDiaboloInscriptionsComponent implements OnInit, OnDestroy {

  idFromUrl: number;
  config: ConfigDiabolo;
  paramsLibres: ParamLibre[];
  isEdit: boolean;
  inscriptionsActive: boolean;
  listUsagers: Usager[];
  inscription: DiaboloInsc;
  idFamille: number;
  saving: boolean;
  noAvailablePeriodes: boolean;
  loadingPeriode: boolean;
  isLoaded: boolean;
  periodesAlreadyExist: number[];
  dossierAlreadyExist: boolean;
  loadedParamsLibres: boolean;
  dateNow = moment(new Date()).format("YYYYMMDD");

  treeControl = new NestedTreeControl<PeriodeTreeItem>(this.periodeService.getPeriodeTreeNodeChildren);
  treeSource: MatTreeNestedDataSource<PeriodeTreeItem>;
  treeData: PeriodeTreeItem[];
  isAllExpanded = false;
  selectionModel = new SelectionModel<PeriodeTreeItem>(true);

  inscriptionError: string;
  inscriptionErrorSteps: { stepName: string, label: string, link?: string }[];

  isMobile: boolean;

  private onDestroy$ = new Subject<void>();

  @Input() fromDemarche: boolean;
  @Input() usager: any;
  @Output() save = new EventEmitter<DemarcheFormReponse>();

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private diaboloService: DiaboloService,
    private periodeService: PeriodeService,
    public platformService: PlatformService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private programmePersoService: ProgrammePersoService,
    private helperService: FormHelperService
  ) {
    this.treeSource = new MatTreeNestedDataSource<PeriodeTreeItem>();
  }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    if (!this.fromDemarche) {
      this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    }

    this.loadData().subscribe(_ => {
      if (this.isEdit) {
        if (this.inscription._paramsLibres.length) {
          this.paramsLibres.forEach(confParamLibre => {
            let paramExist = this.inscription._paramsLibres.find(p => p.idParamLibre === confParamLibre.idParamLibre)
            if (paramExist) {
              confParamLibre.valeur = paramExist.valeur
              confParamLibre.idParamLibreValeur = paramExist.idParamLibreValeur ? paramExist.idParamLibreValeur : 0
            }
          })
        }
      }

      if (this.fromDemarche && this.usager) {
        this.inscription.usager = this.listUsagers.find(usager => usager.id === this.usager.id && usager.type === this.usager.type);
        if (!this.inscription.usager) {
          this.dossierAlreadyExist = true;
        } else {
          this.getPeriodesByUsager();
        }
      }

      this.isLoaded = true;
    });
  }

  loadData() {
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        this.idFamille = family.id;

        const loaders = [];

        if (this.idFromUrl) {
          this.isEdit = true;

          // load edit inscription
          loaders.push(this.diaboloService.getInscription(family, this.idFromUrl).pipe(tap((insc: any) => {
            this.inscription = insc.inscription;
            this.periodesAlreadyExist = this.inscription._periodes.map(p => p.idPeriode);
            this.setUpConfig(insc.conf)
            this.setUpPeriodesByUsager(insc.periodesByUsager);
            this.displayErrorsTracesMessagesProgram(insc);
          })));
        } else {

          loaders.push(this.diaboloService.getConfigForUser().pipe(tap((config: ConfigDiabolo) => {
            this.setUpConfig(config)
          })));

          loaders.push(this.familyService.getListUsagers(family, true).pipe(tap(usagers => this.listUsagers = usagers.filter(usager => !usager.inscriptionDiaboloExiste))));
          this.inscription = {};
          this.inscription.dateDebut = moment(new Date()).format("YYYYMMDD");
        }

        return forkJoin(loaders);
      })
    );
  }

  dateChange($event, dateFieldName: string) {

    if (!$event.value) {
      if (dateFieldName === 'dateDebut') {
        this.inscription.dateDebut = moment(new Date()).format('YYYYMMDD');
      }

      if (dateFieldName === 'dateFin') {
        this.inscription.dateFin = '';
      }
    }

    if (this.inscription.usager) {
      this.getPeriodesByUsager();
    }

  }

  setUpConfig(conf: ConfigDiabolo) {
    this.config = conf;
    this.inscriptionsActive = this.diaboloService.checkPermInscription(this.config);
    this.paramsLibres = [...this.config.paramsLibres];
    this.paramsLibres = this.sortParamsLibresByOrdreAndFilterEnabled(this.paramsLibres);
    this.loadedParamsLibres = true;
  }

  setUpPeriodesByUsager(periodeAsTree: PeriodeTreeItem[]) {

    this.selectionModel.clear();

    if (!periodeAsTree.length) {
      this.noAvailablePeriodes = true;
    }

    this.periodesAlreadyExist = this.periodesAlreadyExist?.filter(idPeriode => {
      let tabIdPeriodesAvailable = this.getTabPeriodesAvailableFromTree(periodeAsTree).map(p => p.id);
      return !tabIdPeriodesAvailable.find(id => id === idPeriode)
    })

    this.loadingPeriode = false
    this.treeSource = new MatTreeNestedDataSource<PeriodeTreeItem>();
    this.treeData = this.config.notShowAccueils ? this.removeAccueilNodes(periodeAsTree) : periodeAsTree;

    this.initTreeData();
  }

  displayErrorsTracesMessagesProgram(result) {
    // Get errors & traces from programs
    this.helperService.displayDebugTraces(result.traces);

    if (result.messages && !result?.errors) {
      this.helperService.notifySuccess("", result.messages);
    }

    if (result.errors && result.errors.length > 0) {
      let errorMessage = '';
      result.errors.forEach(e => errorMessage += `<li>${e}</li>`);
      this.snackbar.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  sortParamsLibresByOrdreAndFilterEnabled(paramsLibres: ParamLibre[]): ParamLibre[] {
    return paramsLibres.sort((a: ParamLibre, b: ParamLibre) => {
      if (a.ordre < b.ordre) return -1;
      if (a.ordre > b.ordre) return 1;
      return 0;
    }).filter(param => param.enabled);
  }

  checkParamsLibres(): boolean {
    return this.paramsLibres
      .filter(paramLib => paramLib.obligatoire)
      .every(paramLibre => paramLibre.valeur || paramLibre.idParamLibreValeur);
  }

  // Fonction pour retirer les accueils du tree
  removeAccueilNodes(nodes: PeriodeTreeItem[]): PeriodeTreeItem[] {
    nodes?.forEach(etab => {
      let periodes = [];
      etab.accueils?.forEach(acc => {
        periodes.push(...acc.periodes)
      })
      etab.periodes = periodes;
      delete etab.accueils;
    })
    return nodes;
  }

  getPeriodesByUsager() {
    const usager = this.inscription.usager;
    this.noAvailablePeriodes = false;
    this.inscriptionError = null;
    this.treeSource = null;

    if (usager) {
      let idEnfant = usager.type.toLowerCase() === "enfant" ? usager.id : 0;
      let idAdulte = usager.type.toLowerCase() === "adulte" ? usager.id : 0;

      let dateDebut = this.inscription.dateDebut && moment(this.inscription.dateDebut).isValid() ? moment(this.inscription.dateDebut).format('YYYYMMDD') : moment(new Date()).format('YYYYMMDD');
      let dateFin = this.inscription.dateFin && moment(this.inscription.dateFin).isValid() ? moment(this.inscription.dateFin).format('YYYYMMDD') : '';
      this.loadingPeriode = true;

      this.diaboloService.getPeriodesByUsagers(this.idFamille, idEnfant, idAdulte, dateDebut, dateFin)
        .subscribe(res => {
          if (res.error) {
            this.inscriptionError = res.error;
            this.inscriptionErrorSteps = res.errorSteps;
            this.inscriptionErrorSteps?.forEach(err => err.link = this.generateLinkForErrorStep(res.error, err.stepName));
            this.loadingPeriode = false;
            this.selectionModel.clear();
          } else {
            this.setUpPeriodesByUsager(res.treeData);
          }
        });

    }
  }

  generateLinkForErrorStep(errorType: string, step: string) {
    const idUsagerEnfant = this.inscription.usager.type === "enfant" ? this.inscription.usager.id : 0;

    switch (errorType) {
      case 'enfant': return `/account/children/edit/${idUsagerEnfant}/${step}`;
      case 'family':
      case 'famille': return `/account/foyer/${step}`;
      case 'user': return `/account/user/${step}`;
      case 'conjoint': return `/account/conjoint/${step}`;
    }

    return '';
  }


  getTabPeriodesAvailableFromTree(treeData: PeriodeTreeItem[]): PeriodeTreeItem[] {
    let periodes: PeriodeTreeItem[] = [];
    treeData.forEach(etab => {
      etab.accueils.forEach(acc => {
        acc.periodes.forEach(p => {
          periodes.push(p)
        })
      });
    });
    return periodes;
  }

  onChangeUsager($event) {
    this.inscription.usager = $event.value;
    this.getPeriodesByUsager();
  }

  runProgramsOnChangePeriodes(onload: boolean = false) {

    if (this.config?.programsOnSelectPeriode.length && ((this.selectionModel?.selected.length && !onload) || onload)) {
      let inscription = this.mapToSave(this.inscription);
      this.loadedParamsLibres = false;
      this.programmePersoService.executeProgrammePersoInscDia(inscription, this.idFamille).subscribe((result: any) => {

        this.displayErrorsTracesMessagesProgram(result)

        if (result.conf && !result?.errors) {
          this.config = result.conf;
          this.paramsLibres = [...this.config.paramsLibres];
          this.paramsLibres = this.sortParamsLibresByOrdreAndFilterEnabled(this.paramsLibres);
          this.loadedParamsLibres = true;
        }
      })

    }

  }

  getSortedList(data: PeriodeTreeItem[]) {
    return data.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  initTreeData() {
    this.treeData = this.getSortedList(this.treeData);

    this.treeSource.data = this.treeData;
    this.treeControl.dataNodes = this.treeData;

    if (this.isEdit) {

      const tabIdsPeriodes = this.inscription._periodes.map(periode => {
        return { id: periode.idPeriode, disabled: !periode.isNewPeriode }
      });

      this.treeData.forEach((etab) => {
        if (etab.accueils?.length) {
          etab.accueils?.forEach(accueil => {
            accueil.periodes?.forEach(p => {
              if (tabIdsPeriodes.some(item => item.id === p.id)) {
                this.itemSelectionToggle(p);
                if (tabIdsPeriodes.some(item => item.id === p.id && item.disabled)) {
                  this.disableParents(p)
                }
              }
            })
          })
        }
        if (this.config.notShowAccueils && etab.periodes?.length) {
          etab.periodes?.forEach(p => {
            if (tabIdsPeriodes.some(item => item.id === p.id)) {
              this.itemSelectionToggle(p);
              if (tabIdsPeriodes.some(item => item.disabled && p.id === item.id)) {
                this.disableParents(p)
              }
            }
          })
        }
      })

    }
  }

  disableParents(node: PeriodeTreeItem): void {
    if (node) {
      let parent: PeriodeTreeItem;
      node.disabled = true;

      if (node.level === "periode") {
        if (this.config.notShowAccueils) {
          parent = this.treeControl.dataNodes.find((n) => {
            return n.periodes.some(p => p.name === node.name && p.id === node.id);
          })
        } else {
          let etab = this.treeControl.dataNodes.find((n) => {
            return n.accueils.find(acc => acc.periodes.some(p => p.name === node.name && p.id === node.id));
          })
          parent = etab.accueils.find(acc => acc.periodes.some(p => p.name === node.name && p.id === node.id))
        }
      }

      if (node.level === "accueil") {
        parent = this.treeControl.dataNodes.find((n) => {
          return n.accueils.find(acc => acc.name === node.name && acc.id === node.id);
        })
      }

      if (parent) {
        this.disableParents(parent);
      }
    }
  }

  itemSelectionToggle(node: PeriodeTreeItem): void {
    this.selectionModel.toggle(node);

    const descendants = this.treeControl.getDescendants(node);
    this.selectionModel.isSelected(node)
      ? this.selectionModel.select(...descendants)
      : this.selectionModel.deselect(...descendants);
  }

  isPeriode(_, node) {
    return node && node.level === "periode";
  }

  descendantsPartiallySelected(node: PeriodeTreeItem): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.selectionModel.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  toggleExpandAll() {
    if (this.isAllExpanded) {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.expandAll();
    }

    this.isAllExpanded = !this.isAllExpanded;
  }

  descendantsAllSelected(node: PeriodeTreeItem): boolean {
    const nodeSelected = this.selectionModel.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const hasNoPeriodes = node.level === "accueil" && node.periodes.length === 0;
    const isPeriode = node.level === "periode";

    if (hasNoPeriodes) return nodeSelected;
    const allSelected =
      descendants.every((child) => this.selectionModel.isSelected(child));

    if (allSelected && !nodeSelected) {
      this.selectionModel.select(node);
    } else if (!allSelected && nodeSelected) {
      this.selectionModel.deselect(node);
    }
    if (isPeriode) { this.selectionModel.select(node) }


    return allSelected;
  }

  checkValidityDates(dateDebut, dateFin) {
    if (this.isEdit) {
      return true;
    }

    const debut = dateDebut && moment(dateDebut).isValid() ? moment(dateDebut) : moment(new Date());
    const fin = dateFin && moment(dateFin).isValid() ? moment(dateFin) : moment('');

    if (debut.isValid() && fin.isValid() && debut.isSameOrAfter(this.dateNow)) {
      return fin.isSameOrAfter(debut);
    }

    if (debut.isValid() && debut.isSameOrAfter(this.dateNow)) {
      return true
    }

    return false
  }

  mapToSave(data: DiaboloInsc): any {

    let dataToSave: any = {};
    dataToSave.idInscriptionDiabolo = data.idInscriptionDiabolo;
    let selectedPeriode = this.selectionModel.selected.filter(selection => selection.level === 'periode').map(periode => {
      return periode.id;
    });
    dataToSave.periodes = this.periodesAlreadyExist?.length ? [...new Set([...selectedPeriode, ...this.periodesAlreadyExist])] : selectedPeriode;
    dataToSave.dateDebut = data.dateDebut && moment(data.dateDebut).isValid() ? moment(data.dateDebut).format('YYYYMMDD') : moment(new Date()).format('YYYYMMDD');
    dataToSave.dateFin = data.dateFin && moment(data.dateFin).isValid() ? moment(data.dateFin).format('YYYYMMDD') : '';
    dataToSave.commentaire = data.commentaire ? data.commentaire : '';
    dataToSave.usager = {
      type: data.usager.type,
      id: data.usager.id,
      nom: data.usager.nom,
      prenom: data.usager.prenom
    };
    dataToSave._paramsLibres = this.paramsLibres.map(param => {
      if (param.nature === "Liste") {
        return { idParamLibre: param.idParamLibre, idParamLibreValeur: param.idParamLibreValeur, nature: param.nature }
      }
      return { idParamLibre: param.idParamLibre, valeur: param.valeur, nature: param.nature }
    });

    return dataToSave;
  }

  onClickValidateInscription() {
    let inscriptionData = this.mapToSave(this.inscription);
    const family = this.familyService.currentFamily;
    this.saving = true;

    this.diaboloService.saveInscription(inscriptionData, family.id).pipe(
      finalize(() => this.saving = false)
    ).subscribe((res: any) => {
      if (!res.errors) {
        this.snackbar.info('info.success.saving');
        if (this.fromDemarche) {
          let inscriptionData = { id: res.id, type: 'Inscription', usager: this.usager };
          this.save.emit(inscriptionData);
        } else {
          this.router.navigate(['/account/diabolo-inscriptions']);
        }
      } else {
        res.errors.forEach((err: { message: string }) => {
          let message = err.message as string;

          if (message === 'diabolo.error.doublon_inscription_periode') {
            message = this.translate.instant(message);
            this.snackbar.error(message);
          }
        })
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
