import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Reservation, ReservationPanierItem, PanierReservation } from '@app/models/reservation';
import { SnackbarService, FacturationService, PlatformService } from '@app/services';
import { MatDialog } from '@angular/material/dialog';
import { DATE_FORMAT } from '@app/services/planning.service';
import { Facture } from '@app/models/facturation';
import { interval, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import moment from 'moment';
import { ReservationService } from '@app/services/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentRedirectDialogComponent } from '@app/components/_common/payment-redirect-dialog/payment-redirect-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/components/_common/confirm-dialog/confirm-dialog.component';

interface PanierRegieData extends ReservationPanierItem {
  errors?: string[];
  checkedReservations?: number;
  useAvoirMontant?: number;
  useAvoir?: boolean;
  toPay?: number;
  typePaiement: string;
  pausePaiement: boolean;
}

interface PanierValidateResult {
  url: string;
  params?: any;
  noPaymentNeeded?: boolean;
  errors?: { errorMessage: string }[];

  [addProps: string]: any;
}

@Component({
  selector: 'app-reservation-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss']
})
export class ReservationPanierComponent implements OnInit, OnDestroy {

  @ViewChild('detailDialog') reservationDetail: TemplateRef<MatDialog>;
  @ViewChild('paymentDialog') paymentDialog: TemplateRef<any>;

  dataByRegie: PanierRegieData[];

  validateSuccess: boolean;

  factureLoading = false;
  regieConfigLoading = false;
  currentTypePaiement = '';

  checkPausePaiement: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private snackbar: SnackbarService,
    private reservationService: ReservationService,
    private facturationService: FacturationService,
    private translateService: TranslateService,
    private platformService: PlatformService,
  ) { }

  ngOnInit(): void {
    this.reloadPanierData();
  }

  reloadPanierData() {
    this.dataByRegie = null;

    this.reservationService.getReservationCart().subscribe(data => {
      this.dataByRegie = (data as PanierRegieData[]);
      // Select all reservations by default
      this.dataByRegie.forEach(regie => {
        regie.reservations.forEach(rr => rr.selected = !rr.alreadyPaying);
        this.reloadRegieFacture(regie);
        this.checkPausePaiement.push(interval(1000).subscribe(_ => {
          regie.pausePaiement = this.facturationService.checkPausePaiement(regie);
        }));
      });
    });
  }

  reloadRegieFacture(regieData: PanierRegieData) {
    const selected = regieData.reservations.filter(r => r.selected).map(r => r.id) as number[];

    regieData.checkedReservations = selected.length;

    regieData.facture = null;
    regieData.errors = null;

    if (regieData.checkedReservations < 1) {
      this.factureLoading = false;
      return;
    }

    this.factureLoading = true;

    this.reservationService.getFactureForReservations(regieData.idRegie, selected).subscribe(data => {
      this.factureLoading = false;
      if (data.errors && data.errors.length) {
        // some message are translatable, not all ...
        regieData.errors = data.errors.map(err => err.type === 'no_facture' ? err.type : err.errorMessage);
      } else {
        regieData.facture = data.facture || {} as Facture;
        regieData.montantAvoir = data.montantAvoir;
        regieData.useAvoirMontant = Math.min(regieData.facture.montant, regieData.montantAvoir);
        regieData.useAvoir = !!regieData.montantAvoir;
        this.updateTotal(regieData);
      }
    });
  }

  getExpireText(dateTime: string) {
    const today = moment().format(DATE_FORMAT);
    const momentDate = moment(dateTime);

    return today === momentDate.format(DATE_FORMAT) ? momentDate.format('HH:mm') : 'le ' + momentDate.format('DD/MM/YYYY à HH:mm');
  }

  updateTotal(regieData: PanierRegieData) {
    if (!isNaN(regieData.useAvoirMontant)) {
      regieData.useAvoirMontant = Math.max(Math.min(regieData.useAvoirMontant, regieData.montantAvoir), 0);
      regieData.toPay = this.getTotalAmount(regieData);
    }
  }

  getTotalAmount(regieData: PanierRegieData) {
    if (!regieData.montantAvoir || !regieData.useAvoir || isNaN(regieData.useAvoirMontant)) {
      return regieData.facture.montant;
    }

    return regieData.facture.montant - regieData.useAvoirMontant;
  }

  openReservationDetails(reservation: Reservation) {
    this.dialog.open(this.reservationDetail, {
      data: { reservation },
      minWidth: '30vw'
    });
  }

  goPay(regieData: PanierRegieData) {
    const redirectDialog = this.dialog.open(PaymentRedirectDialogComponent, {
      disableClose: true
    });

    this.currentTypePaiement = regieData.typePaiement;

    let tabRef;

    if (regieData.typePaiement === 'payfip' && regieData.toPay > 0) {
      tabRef = this.openPayfipTab();
      // this.platformService.watchForTabClose(tabRef).subscribe(() => redirectDialog.close());
      this.platformService.watchForTabClose(tabRef).subscribe(() => {
        this.reloadPanierData();
        redirectDialog.close();
      });
    }
    // Reset errors (shouldn't mix facture errors & validate errors)
    regieData.errors = null;

    const selected = regieData.reservations.filter(r => r.selected).map(r => r.id) as number[];

    this.facturationService.getRegieConfig(regieData.idRegie).pipe(
      switchMap(regieConfig => {
        if (regieConfig && regieConfig.activePaiement) {
          redirectDialog.componentInstance.regie = regieConfig;
          const backUrl = this.facturationService.getPayBackUrl(regieConfig.typePaiement, 'reservations-panier');

          return this.reservationService.validatePanier(regieData.idRegie, selected, this.getTotalAmount(regieData), backUrl);
        } else {
          this.snackbar.error('Paiement en ligne désactivé sur cette régie');
          return of(null);
        }
      })
    ).subscribe((result: PanierValidateResult) => {
      // "error" (without s) can happen if something goes wrong with the pay request
      if (result && !result.error) {
        if (result.errors) {
          regieData.errors = result.errors.map(err => err.errorMessage);
          redirectDialog.close();
        } else if (result.noPaymentNeeded) {
          this.validateSuccess = true;
          this.reloadPanierData();
          window.scrollTo(0, 0);
          redirectDialog.close();
        } else if (result.url) {
          if (result.params) {
            const form = this.facturationService.buildPaymentForm(result.url, result.params);

            (this.elementRef.nativeElement as HTMLElement).appendChild(form);
            form.submit();
            setTimeout(() => form.remove()); // not so useful as we directly move from current page ..
          } else if (regieData.typePaiement === 'payfip') {
            tabRef.location = result.url;
          } else {
            window.location.href = result.url;
          }

          if (tabRef) {
            redirectDialog.componentInstance.setState('paying');
          }
        }
      } else {
        if (regieData.typePaiement === "payfip") {
          this.snackbar.error(result.error);
          tabRef.close();
          redirectDialog.close();
        } else {
          this.snackbar.error('Erreur : impossible de récupérer le résultat');
          redirectDialog.close();
        }
      }
    });
  }

  openPayfipTab() {
    const tab = window.open('', '_blank');
    tab.document.body.innerHTML = '<h1>' + this.translateService.instant('facture.paiement.redirect_message_tab') + '</h1>';
    tab.document.title = 'Redirection vers la plateforme de paiement';

    return tab;
  }

  selectReservation(reservation: PanierReservation, regie: PanierRegieData, event) {
    if (this.factureLoading) {
      return;
    }

    // prevent checkbox to change "selected" state
    if (event instanceof MouseEvent) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    if (reservation.alreadyPaying && !reservation.selected) {

      const dialog = this.dialog.open(ConfirmDialogComponent, {
        panelClass: 'already-paying-notice-dialog',
        data: {
          title: 'Paiement en attente',
          message: 'Attention, un autre paiement est déjà en attente pour cette réservation.<br><br>Souhaitez-vous la sélectionner à nouveau et effectuer un autre paiement pour celle-ci ?',
          confirmText: 'Oui',
          cancelText: 'Non'
        } as ConfirmDialogData
      });

      this.platformService.adaptDialogToScreen(dialog);

      dialog.afterClosed().subscribe(response => {
        // user manually decided to "unlock" the "re"-payment of this reservation
        if (response) {
          reservation.alreadyPaying = false;
          reservation.selected = true;

          // find corresponding regie to reload facture
          this.reloadRegieFacture(regie);
        }
      });
    } else {
      reservation.selected = !reservation.selected;
      this.reloadRegieFacture(regie);
    }
  }

  cancelReservation(reservation: PanierReservation) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Voulez-vous annuler la réservation n° <b>${reservation.number}</b> ?`,
        confirmText: 'Oui',
        cancelText: 'Non'
      } as ConfirmDialogData
    });

    this.platformService.adaptDialogToScreen(dialog);

    dialog.afterClosed().subscribe(responseDialog => {
      if (responseDialog) {
        this.reservationService.cancelReservationFromCart(reservation).subscribe((response: any) => {
          if (response.errorMessage) {
            this.snackbar.error(response.errorMessage)
          } else {
            this.snackbar.info('Annulation réussie !')
            this.reloadPanierData();
          }
        }, error => {
          this.snackbar.error('Une erreur est survenue lors de l\'annulation.')
        })
      }
    });
  }

  ngOnDestroy() {
    this.checkPausePaiement.forEach((subscription) => subscription.unsubscribe())
  }
}
