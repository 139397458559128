import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { tableAnimation } from '@app/models/animination-table';
import { AssmatAgrementSecondaire, AssmatAvailabilityAgrements, DisplayedAssmatAvailability } from '@app/models/ass-mat';
import { PermissionService, PlatformService, SnackbarService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assmat-availability',
  templateUrl: './assmat-availability.component.html',
  styleUrls: ['./assmat-availability.component.scss'],
  animations: [tableAnimation,
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class AssmatAvailabilityComponent implements OnInit, OnDestroy {

  deleting: number;
  formTitle: string;
  displayedColumns: string[] = ['agrementSecondaire', 'capacite', 'dateDebut', 'dateFin', 'action'];
  dataSource: MatTableDataSource<any>;
  loading = true;
  listAvailability: DisplayedAssmatAvailability[];
  listAgrementsSecondaires: AssmatAgrementSecondaire[];
  expandedAvailability: any;
  saving: boolean = false;
  readOnly: boolean;
  pageTitle: string;
  isMobile: boolean;

  private onDestroy$ = new Subject<void>();

  @ViewChild('table') table: MatTable<DisplayedAssmatAvailability>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private assmatService: AssmatService,
    private dialog: MatDialog,
    public platformService: PlatformService,
    private snackbar: SnackbarService,
    private permService: PermissionService
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    this.readOnly = !this.permService.hasPermission('disponibilites_edit');
    this.assmatService.getAvailability(this.userService.currentUser.idAssmat).subscribe((data: AssmatAvailabilityAgrements) => {
      this.listAvailability = data.disponibilites;
      this.listAgrementsSecondaires = data.agrementsSecondaires;
      this.dataSource = new MatTableDataSource(this.listAvailability || []);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this.dataSource.sortingDataAccessor = (agrement, property) => {
        switch (property) {
          case 'agrementSecondaire': { return agrement.agrementSecondaire.id }
          default: return agrement[property];
        }
      }

      this.loading = false;
    })
  }

  addAvailability() {
    this.updateTable(this.buildNewAvailability());
  }

  buildNewAvailability() {
    let listIds = this.listAvailability.map(item => item.idDisponibiliteManuelle);
    let minId = Math.min(...listIds);

    return {
      dateDebut: new Date(),
      dateFin: '',
      idDisponibiliteManuelle: minId < 0 ? minId - 1 : -1,
      capacite: 0,
      commentaire: '',
      agrementSecondaire: {}
    } as DisplayedAssmatAvailability;
  }

  deleteAvailability(availability: DisplayedAssmatAvailability) {
    const ref = this.dialog.open(ConfirmDialogComponent, { data: { message: `Supprimer cette disponibilité ?` } });

    ref.afterClosed().subscribe(result => {
      if (result) {

        this.deleting = availability.idDisponibiliteManuelle;

        availability.rowEnterLeaveAnimation = 'deleted';

        const i = this.listAvailability.findIndex(val => val.idDisponibiliteManuelle === availability.idDisponibiliteManuelle)

        setTimeout(() => {
          this.listAvailability.splice(i, 1);
          this.refreshTable();
        }, 1);

        this.deleting = null;
      }
    });
  }


  updateTable(newRow: DisplayedAssmatAvailability) {
    newRow.rowEnterLeaveAnimation = 'new';
    newRow.highlightRowsAnimation = 'highlight';
    this.listAvailability.push(newRow);
    setTimeout(() => {
      newRow.rowEnterLeaveAnimation = null;
      newRow.highlightRowsAnimation = null;
    }, 1000);

    setTimeout(() => {
      this.refreshTable();
    }, 1);
  }

  refreshTable() {
    this.dataSource.data = this.listAvailability;
    this.table.renderRows();
  }

  compareWithFn(agrementSecondaire1: AssmatAgrementSecondaire, agrementSecondaire2: AssmatAgrementSecondaire) {
    return agrementSecondaire1 && agrementSecondaire2 ?
      agrementSecondaire1.id === agrementSecondaire2.id :
      agrementSecondaire1 === agrementSecondaire2;
  }

  save() {
    this.saving = true;
    this.assmatService.saveAvailability(this.userService.currentUser.idAssmat, this.listAvailability).subscribe(_ => {
      this.snackbar.info('Modification effectuée');
      this.saving = false;
      this.onBack();
    }, err => this.snackbar.error('Erreur lors de la modification'));
  }

  onBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
