<mat-drawer-container autosize>
  <mat-drawer-content style="width:100%;">

    <ng-template appTopbarActions>
      <button mat-mini-fab color="accent" (click)="toggleOptions()" matTooltip="Options d'affichage">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-template>

    <div class="conteneur thin-scrollbar" *ngIf="!isLoading; else loader">

      <mat-card class="info-card" *ngIf="config.infoMessage" [portailCustomStyle]="config.styleCardInfo">
        <div [innerHtml]="config.infoMessage | safe"></div>
      </mat-card>

      <mat-card *ngIf="permToCreate" class="actions-card card-add">
        <mat-card-header class="card-header fx-direction-row fx-center-center">
          <mat-card-title class="fx-direction-row fx-center-center" style="text-align: center;">
            {{ 'mandat_sepa.new_mandat_sepa'|trans }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 20px;" class="fx-direction-row fx-center-center">
          <button mat-fab color="primary" matTooltip="{{ 'mandat_sepa.new_mandat_sepa'|trans }}"
            routerLink="/account/mandats-sepa/new">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

      <div class="mandat-list" *ngIf="!isLoading">
        <!-- [ngStyle]="{'opacity': (!mandat.estActif && !mandat.portailEnregistrementExist) && mandat.allDocsExist ? 0.7 : 1}" -->
        <mat-card class="mandat-card" *ngFor="let mandat of mandatsSepaFiltered; index as i">
          <mat-card-header class="card-header fx-direction-row fx-between-start">
            <mat-card-title class="fx-direction-row fx-center-center">

              <div *ngIf="mandat.idSEPAMandat > 0 && mandat.estActif" matTooltip="Actif"
                class="fx-direction-row fx-start-center" style="margin-right: 10px;">
                <mat-icon color="primary">check_circle</mat-icon>
              </div>
              <div *ngIf="mandat.idSEPAMandat > 0 && !mandat.estActif" matTooltip="Désactivé"
                class="fx-direction-row fx-start-center" style="margin-right: 10px;">
                <mat-icon color="warn">block</mat-icon>
              </div>

              <span>
                {{
                mandat.numRUM ? 'N° Mandat (RUM) : ' + mandat.numRUM : '[N° Mandat (RUM) déterminé à la validation]'
                }}
              </span>

            </mat-card-title>

            <button *ngIf="mandat.idSEPAMandat > 0 && !mandat.portailEnregistrementExist && !mandat.allDocsExist"
              mat-raised-button color="primary" matTooltip="Imprimer" (click)="openPDFNewTab(mandat)"
              [disabled]="loadingPreview && idSelectedMandatToPrint === mandatsSepaFiltered[i].idSEPAMandat"
              class="fx-direction-row fx-center-center">
              <mat-icon *ngIf="!loadingPreview || idSelectedMandatToPrint !== mandatsSepaFiltered[i].idSEPAMandat"
                style="margin-right: 5px;">print</mat-icon>
              <mat-progress-spinner
                *ngIf="loadingPreview && idSelectedMandatToPrint === mandatsSepaFiltered[i].idSEPAMandat"
                mode="indeterminate" strokeWidth="2" diameter="24" color="accent" class="icon-loader"
                style="margin-right: 5px;">
              </mat-progress-spinner>
              <span *ngIf="!(platformService.isMobile$ | async)">{{'mandat_sepa.print' | trans}}</span>
            </button>
          </mat-card-header>

          <mat-card-content style="margin-top: 15px;">

            <div>
              <label>Créancier : </label>
              <span>{{ mandat.labelCreancier ? mandat.labelCreancier : mandat.creancier }}</span>
            </div>
            <div>
              <label>Nom Prénom : </label>
              <span>{{mandat.nomPrenom}}</span>
            </div>
            <div>
              <label>Banque : </label>
              <span>{{mandat.banqueMandat}}</span>
            </div>
            <div>
              <label>IBAN : </label>
              <span>{{ mandat.iban }}</span>
            </div>
            <div>
              <label>BIC : </label>
              <span>{{mandat.bic}}</span>
            </div>

            <div style="margin-top: 20px;"
              *ngIf="mandat.piecesAFournir?.length && !mandat.portailEnregistrementExist && !mandat.allDocsExist"
              class="fx-direction-col">
              <div *ngIf="config.docMessage" [innerHtml]="config.docMessage | safe"></div>
              <ng-container *ngFor="let piece of mandat.piecesAFournir">
                <!--*ngIf="!piece.docExist"-->
                <div class="piece-container">
                  <div class="piece-content">
                    <div class="piece-title">
                      <b>{{ piece.name }}</b>
                    </div>

                    <div class="piece-description">
                      <div [innerHtml]="piece.description | safe"></div>
                    </div>

                    <div class="documents-list">
                      <ng-container *ngFor="let doc of documents">
                        <ng-container *ngIf="doc.idMandat === mandat.idSEPAMandat && doc.idPieceAFournir === piece.id">
                          <div class="file" [matMenuTriggerFor]="documentMenu"
                            [matMenuTriggerData]="{doc: doc, mandat: mandat}">

                            <div *ngIf="documentDownload[doc.idDocumentElectronique] as progress; else icon"
                              class="download-progress">
                              <mat-progress-spinner [value]="progress" diameter="32">
                              </mat-progress-spinner>
                              <span>{{ progress }}%</span>
                            </div>

                            <ng-template #icon>
                              <i *ngIf="getFileIcon(doc) as icon; else default" class="far fa-file-{{ icon }}"></i>
                              <ng-template #default><i class="far fa-file"></i></ng-template>
                            </ng-template>

                            <div class="name-file">
                              {{ doc.nomFichier }}
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>

                  <div class="piece-buttons">
                    <mat-icon color="primary" *ngIf="piece.docExist" style="margin-right: 3px;">check</mat-icon>
                    <button *ngIf="!piece.docExist" mat-mini-fab color="primary" matTooltip="Ajouter un fichier"
                      (click)="inputFile.click()" [disabled]="piece.docExist">
                      <input #inputFile type="file" hidden (change)="onFileSelect($event.target.files, piece, mandat)">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>

              <mat-menu #documentMenu>
                <ng-template matMenuContent let-doc="doc" let-mandat="mandat">
                  <button mat-menu-item (click)="openDocDetails(doc, mandat)">
                    <mat-icon color="primary">visibility</mat-icon>
                    <span>Détails</span>
                  </button>
                  <button mat-menu-item (click)="downloadDocument(doc)">
                    <mat-icon color="accent">get_app</mat-icon>
                    <span>Télécharger</span>
                  </button>
                  <button mat-menu-item (click)="deleteDocument(doc)" [disabled]="doc.idDocumentElectronique > 0">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>Supprimer</span>
                  </button>
                </ng-template>
              </mat-menu>
            </div>


            <div *ngIf="mandat.idSEPAMandat > 0 && mandat.estActif && mandat.allDocsExist"
              class="fx-direction-row fx-end-center" style="margin-top: 15px;">
              <button mat-raised-button (click)="onClickDisabled(mandat)" color="warn"
                class="fx-direction-row fx-center-center">
                <mat-icon style="margin-right: 5px;">block</mat-icon>
                <span>{{'mandat_sepa.disabled_prelevement' | trans}}</span>
              </button>
            </div>

            <div *ngIf="mandat.idSEPAMandat > 0 && mandat.portailEnregistrementExist"
              class="fx-direction-row fx-end-center" style="margin-top: 15px;">
              <em>{{'mandat_sepa.waiting_disable' | trans}}</em>
            </div>

          </mat-card-content>
        </mat-card>

      </div>

      <!-- PAS DE MANDAT -->
      <div *ngIf="!mandatsSepa.length && !permToCreate">
        <h2 style="text-align: center;">{{ 'mandat_sepa.no_mandat_sepa'|trans }}</h2>
      </div>

    </div>

  </mat-drawer-content>

  <!-- OPTIONS AFFICHAGE -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div class="fx-direction-col" style="margin: 5px; padding: 5px;">
      <mat-slide-toggle [(ngModel)]="showEnabled" (ngModelChange)="toggleShowEnabled($event)" class="slideToggle">
        <span>Afficher les mandats de prélèvements SEPA actifs
          (<app-icon type="fa" icon="check-circle" class="icon-decision primary"></app-icon>).
        </span>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showDisabled" (ngModelChange)="toggleShowDisabled($event)" class="slideToggle">
        <span>Afficher les mandats de prélèvements SEPA désactivés (<app-icon type="fa" icon="ban"
            class="icon-decision warn">
          </app-icon>).</span>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showWaiting" (ngModelChange)="toggleShowWaiting($event)" class="slideToggle">
        <span>Afficher les mandats de prélèvements SEPA en attente de traitement.
        </span>
      </mat-slide-toggle>
    </div>

  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>

<!-- DIALOG DISABLED MANDAT -->
<ng-template #disabledMandatDialog let-data>
  <app-dialog-header title="{{ 'mandat_sepa.title_dialog_diabled_mandat_sepa'|trans }}"></app-dialog-header>
  <mat-dialog-content style="margin: 20px;">
    <b>{{ 'mandat_sepa.confirm_disabled_mandat-sepa'|trans:{numRum : data.mandat.numRUM} }}</b>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
      Annuler
    </button>

    <button mat-raised-button (click)="disabledMandat(data.mandat)" color="primary" [disabled]="isSaving">
      <mat-icon>check</mat-icon>
      Valider
      <mat-progress-spinner *ngIf="isSaving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
        class="icon-loader"></mat-progress-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>