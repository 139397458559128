<mat-toolbar mat-dialog-title color="primary">
  <button mat-icon-button [mat-dialog-close] class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">Mandat N° : {{ data.mandat.numRUM }}</span>
</mat-toolbar>

<mat-dialog-content class="thin-scrollbar" style="padding: 0;" *ngIf="pdf; else loader">
  <div class="thin-scrollbar">
    <pdf-viewer [src]="pdf" [render-text]="false" [original-size]="false"
      [ngClass]="{'dimension-pdf-viewer': !(platformService.isMobile$ | async)}"></pdf-viewer>
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onClickDownloadPdf(data.mandat.pdf,data.mandat.numRUM)">
    <mat-icon>download</mat-icon>
    Télécharger
  </button>
</div>

<ng-template #loader>
  <app-loader [message]="'Chargement du document...'" class="margin"></app-loader>
</ng-template>