import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Usager } from '@app/models/consumer';
import { DemarcheItem, DemarcheStep } from '@app/models/demarche';
import { FamilyService, PermissionService } from '@app/services';

@Component({
  selector: 'app-demarche-select-step',
  templateUrl: './demarche-select-step.component.html',
  styleUrls: ['./demarche-select-step.component.scss']
})
export class DemarcheSelectStepComponent implements OnInit {

  @Input() demarche: DemarcheItem;
  @Input() stepper: MatStepper;
  @Input() step: DemarcheStep;
  @Output() onValidate = new EventEmitter<Usager>();
  @Output() onCreate = new EventEmitter<'Enfant' | 'Conjoint'>();
  listUsagers: Usager[];
  selectedUsager: Usager;
  conjointExist: boolean;
  permToAddChild: boolean;
  permToEditFoyer: boolean;

  constructor(
    private familyService: FamilyService,
    private permService: PermissionService
  ) { }

  ngOnInit(): void {

    this.permToAddChild = this.permService.hasPermission('child_create');
    this.permToEditFoyer = this.permService.hasPermission('foyer_edit');

    this.familyService.getListUsagers(this.familyService.currentFamily.id).subscribe(usagers => {
      this.listUsagers = usagers;
      this.conjointExist = this.listUsagers.find(usager => usager.estConjoint) ? true : false;
    })
  }

  onClickToAdd(formName: 'Enfant' | 'Conjoint') {
    this.onCreate.emit(formName)
  }

  onValider() {
    setTimeout(() => {
      this.onValidate.emit(this.selectedUsager);
      this.stepper.next();
    });
  }

}
