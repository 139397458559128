import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigMandatSepa, MandatSepa, Pays } from '@app/models/mandat-sepa';
import { FamilyService, PlatformService, SnackbarService } from '@app/services';
import { MandatSepaService } from '@app/services/mandat-sepa.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-mandat-sepa-edit',
  templateUrl: './mandat-sepa-edit.component.html',
  styleUrls: ['./mandat-sepa-edit.component.scss']
})
export class MandatSepaEditComponent implements OnInit, OnDestroy {

  sepaMandatForm: UntypedFormGroup;
  config: ConfigMandatSepa;
  isEdit: boolean = false;
  idFromUrl: number;
  listPays: String[];
  listPaysCodePays: Pays[];
  filteredlistPays: Observable<String[]>;
  famille: any;
  codePays: string;
  pays: string;
  mandatSepa: MandatSepa;
  allMandatsSepaActifs: MandatSepa[];
  isLoading: boolean;
  saving: boolean;
  codeBanqueFR: string;
  codeGuichetFR: string;
  isLoadingSearchBic: boolean;
  familleActive: boolean;
  isMobile: boolean;

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private mandatSepaService: MandatSepaService,
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    public platformService: PlatformService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    this.familleActive = this.familyService.currentFamily.active;

    this.loadData().subscribe(_ => {

      this.filtreCreancierAvailable();

      this.createForm();
      this.setValueFormMandatSepa(this.mandatSepa);
      this.isLoading = false;
    })
  }

  loadData() {
    this.isLoading = true;
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        const loaders = [];

        loaders.push(this.familyService.getFamilyData(family.id).pipe(tap(f => {
          this.famille = f.famille;
        })))

        loaders.push(this.mandatSepaService.getConfigForUser().pipe(tap((config: ConfigMandatSepa) => {
          this.config = config;
        })));

        loaders.push(this.mandatSepaService.getFamilyMandatsSepa(family.id).pipe(tap((allMandats: MandatSepa[]) => {
          this.allMandatsSepaActifs = allMandats.filter(mandat => mandat.estActif == true);//|| (mandat.estActif == false && mandat.portailEnregistrementExist)
        })));

        loaders.push(this.mandatSepaService.getListPays().pipe(tap(pays => {
          this.listPaysCodePays = pays;
        })));

        if (this.idFromUrl) {
          this.isEdit = true;

          // load edit mandat sepa
          loaders.push(this.mandatSepaService.getMandatSepa(family, this.idFromUrl).pipe(tap((mandat: MandatSepa) => {
            this.mandatSepa = mandat;
          })));
        }

        return forkJoin(loaders);
      })
    );
  }

  filtreCreancierAvailable() {
    if (this.isEdit) {
      this.config.tabCreanciers = this.config.tabCreanciers.filter(creancier => !this.allMandatsSepaActifs
        .some(mandat => mandat.idSEPACreancier === creancier.idSEPACreancier && creancier.idSEPACreancier !== this.mandatSepa.idSEPACreancier));
    } else {
      this.config.tabCreanciers = this.config.tabCreanciers.filter(creancier => !this.allMandatsSepaActifs
        .some(mandat => mandat.idSEPACreancier === creancier.idSEPACreancier));
    }
  }

  setValueFormMandatSepa(mandatSepa: MandatSepa) {
    for (const key in mandatSepa) {
      if (Object.prototype.hasOwnProperty.call(mandatSepa, key)) {
        const element = mandatSepa[key];
        if (Object.keys(this.sepaMandatForm.value).some(x => x == key)) {
          this.sepaMandatForm.get(key).setValue(element);
        }
      }
    }
  }

  createForm() {
    this.sepaMandatForm = this.formBuilder.group({
      idSEPACreancier: ['', Validators.required],
      idSEPAMandat: [''],
      nomPrenom: [!this.isEdit ? `${this.famille.debiteurNom} ${this.famille.debiteurPrenom}` : '', Validators.required],
      adresse: [!this.isEdit ? `${this.famille.debiteurRue}` : '', Validators.required],
      codePostal: [!this.isEdit ? `${this.famille.codePostal}` : '', [Validators.required, Validators.maxLength(5)]],
      ville: [!this.isEdit ? `${this.famille.debiteurVille}` : '', Validators.required],
      pays: [''],
      codePays: [''],
      iban: ['', [Validators.required, this.validateIban.bind(this)]],
      bic: ['', [Validators.required, this.validateBic.bind(this)]],
      banqueMandat: ['', [Validators.required]],
      paiement: ['1', [Validators.required]],
      lieuSignature: ['', Validators.required]
    });
  }

  validateIban(control: UntypedFormControl) {
    const iban = control.value.toUpperCase();
    const ibanWithoutSpaces = iban.replace(/\s/g, '');// Supprime les espaces de l'IBAN
    const countryCode = ibanWithoutSpaces.substring(0, 2);

    if (iban === "") {
      this.codeBanqueFR = "";
      this.codeGuichetFR = "";
      return { required: true };
    }

    if (!this.listPaysCodePays.some((pays: Pays) => pays.code.toUpperCase() === countryCode)) {
      this.codePays = '';
      return { invalidPays: true }; // pays non pris en charge
    } else {
      this.codePays = countryCode;
      this.pays = this.listPaysCodePays.find(pays => pays.code.toUpperCase() === countryCode).pays;
    }

    if (!/^[A-Z]{2}[0-9]{2}[0-9A-Z]{1,30}$/.test(ibanWithoutSpaces) || (countryCode === 'FR' && ibanWithoutSpaces.length !== 27)) {
      this.codeBanqueFR = "";
      this.codeGuichetFR = "";
      return { invalidIbanFormat: true }; // Format de l'IBAN invalide
    }

    if (countryCode === 'FR' && ibanWithoutSpaces.length === 27) {

      const reorderedIban = ibanWithoutSpaces.substr(4) + ibanWithoutSpaces.substr(0, 4);

      // Convertir les lettres en chiffres (A = 10, B = 11, ..., Z = 35)
      const numericIban = reorderedIban.replace(/[A-Z]/g, (char) => {
        return (char.charCodeAt(0) - 55).toString();
      });
      // Calculer le modulo 97 de l'IBAN numérique
      let remainder = BigInt(numericIban) % BigInt(97);

      // Vérifier si le reste est égal à 1
      if (remainder !== BigInt(1)) {
        return { invalidIban: true };
      }

      // Séparer le code banque et le code guichet
      this.codeBanqueFR = ibanWithoutSpaces.substr(4, 5);
      this.codeGuichetFR = ibanWithoutSpaces.substr(9, 5);
    } else {
      this.codeBanqueFR = "";
      this.codeGuichetFR = "";
    }
    this.searchBic()
    return null;
  }

  searchBic() {
    if (this.codePays === 'FR' && (this.codeBanqueFR && this.codeGuichetFR) && !this.sepaMandatForm.get('bic').valid) {
      this.isLoadingSearchBic = true;
      this.mandatSepaService.getBicByBanqueGuichet(this.codeBanqueFR, this.codeGuichetFR).subscribe((data: any) => {

        if (data.codeBIC) {
          this.sepaMandatForm.get('bic').setValue(data.codeBIC);
        }

        if (data.designation) {
          this.sepaMandatForm.get('banqueMandat').setValue(data.designation);
        }
        this.isLoadingSearchBic = false;
      });
    }
  }

  formatBic(event) {
    const input = event.target as HTMLInputElement;
    const currentPosition = input.selectionStart;
    let formattedValue = input.value.replace(/\s/g, '').toUpperCase();
    input.value = formattedValue.trim();
    input.setSelectionRange(currentPosition, currentPosition);
  }

  formatPays(event) {
    const input = event.target as HTMLInputElement;
    let formattedValue = input.value.toUpperCase();
    input.value = formattedValue;
  }

  formatIban(event) {
    const input = event.target as HTMLInputElement;

    let formattedValue = input.value.replace(/\s/g, '').toUpperCase();

    if (formattedValue.length > 4) {
      formattedValue = formattedValue.replace(/(.{4})/g, '$1 ');
    }

    input.value = formattedValue.trim();
  }

  validateBic(control) {
    const bic = control.value;

    if (bic === "") {
      return { required: true };
    }

    if (!/^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/.test(bic) ||
      (this.sepaMandatForm.get('iban').valid && this.codePays === 'FR' && !this.validateBICFr(bic))) {
      return { invalidBicFormat: true }; // Format du BIC invalide
    }

    return null; // BIC valide
  }

  validateBICFr(bic: string) {
    // Vérifier que la longueur du BIC est soit 8 (sans code d'agence) ou 11 (avec code d'agence) caractères
    if (bic.length !== 8 && bic.length !== 11) {
      return false;
    }

    // Vérifier que les 4 premiers caractères sont des lettres majuscules (code de banque)
    if (!/^[A-Z]{4}$/.test(bic.substring(0, 4))) {
      return false;
    }

    // Vérifier que les 2 caractères suivants sont des lettres majuscules (code d'emplacement)
    if (!/^[A-Z0-9]{2}$/.test(bic.substring(6, 8))) {
      return false;
    }

    // Si la longueur est de 11 caractères, vérifier que les 3 derniers caractères sont des lettres/digits (code d'agence)
    if (bic.length === 11 && !/^[A-Z0-9]{3}$/.test(bic.substring(8, 11))) {
      return false;
    }

    // Toutes les vérifications sont passées, le BIC est valide
    return true;
  }

  onSubmit() {
    if (this.sepaMandatForm.valid) {
      this.saving = true;
      this.sepaMandatForm.get('pays').setValue(this.pays);
      this.sepaMandatForm.get('codePays').setValue(this.codePays);

      let ibanValue = this.sepaMandatForm.get('iban')?.value;
      ibanValue = ibanValue.replace(/\s/g, '');
      this.sepaMandatForm.get('iban').patchValue(ibanValue)

      this.mandatSepa = this.sepaMandatForm.value;
      this.mandatSepa.estActif = false;

      this.mandatSepaService.saveMandatSepa(this.mandatSepa, this.familyService.currentFamily.id).subscribe((res: any) => {
        if (res.errorIban) {
          this.snackbar.error(res.errorIban);
          this.saving = false;
        } else {
          this.snackbar.info('info.success.saving');
          this.router.navigate(['/account/mandats-sepa']);
        }
      }, err => {
        this.snackbar.error("Erreur lors de l'enregistrement"); console.log(err);
      })
    }

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}