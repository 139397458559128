import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { MenuService } from '@app/services/menu.service';
import { Observable, merge, fromEvent, interval, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class PlatformService {

  private isMobileSubject = new BehaviorSubject<boolean>(false);
  private isXSmallSubject = new BehaviorSubject<boolean>(false);
  private isSmallSubject = new BehaviorSubject<boolean>(false);
  private isMediumSubject = new BehaviorSubject<boolean>(false);
  private isLargeSubject = new BehaviorSubject<boolean>(false);
  private isXLargeSubject = new BehaviorSubject<boolean>(false);

  isMobile$ = this.isMobileSubject.asObservable();
  isSmall$ = this.isXSmallSubject.asObservable();
  isMediumSmall$ = this.isSmallSubject.asObservable();
  isMedium$ = this.isMediumSubject.asObservable();
  isLarge$ = this.isLargeSubject.asObservable();
  isXLarge$ = this.isXLargeSubject.asObservable();

  constructor(
    private menuService: MenuService,
    @Inject(PLATFORM_ID) protected platformId: object,
    @Inject(DOCUMENT) protected document: any,
    private viewportRuler: ViewportRuler,
    private breakpointObserver: BreakpointObserver
  ) {
    this.initializeBreakpoints();
  }

  private initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(distinctUntilChanged())
      .subscribe((result) => {
        this.isXSmallSubject.next(result.breakpoints[Breakpoints.XSmall] || false);
        this.isSmallSubject.next(result.breakpoints[Breakpoints.Small] || false);
        this.isMediumSubject.next(result.breakpoints[Breakpoints.Medium] || false);
        this.isLargeSubject.next(result.breakpoints[Breakpoints.Large] || false);
        this.isXLargeSubject.next(result.breakpoints[Breakpoints.XLarge] || false);
        this.isMobileSubject.next(
          result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small] || false
        );
      });
  }

  mainWidth(): number {
    const mainContent = this.menuService.mainContent.getElementRef().nativeElement;
    return (this.isBrowser && mainContent) ? mainContent.offsetWidth : 0;
  }

  get mainWidth$(): Observable<number> {
    return merge(
      fromEvent(window, 'resize').pipe(debounceTime(100)),
      this.menuService.sidenav.openedChange
    ).pipe(
      map(() => this.mainWidth()),
    );
  }

  mainHeight(): number {
    const mainContent = this.menuService.mainContent.getElementRef().nativeElement;
    return (this.isBrowser && mainContent) ? mainContent.offsetHeight : 0;
  }

  get mainHeight$(): Observable<number> {
    return fromEvent(window, 'resize')
      .pipe(
        startWith(() => this.mainHeight()),
        debounceTime(100),
        map(() => this.mainHeight())
      );
  }

  get viewportSize(): Readonly<{ width: number, height: number }> {
    return this.viewportRuler.getViewportSize();
  }

  // getters synchrone => renvoient uniquement la dernière valeur émise
  get isMobile(): boolean {
    return this.isMobileSubject.value;
  }

  get isSmall(): boolean {
    return this.isXSmallSubject.value;
  }

  get isMediumSmall(): boolean {
    return this.isSmallSubject.value;
  }

  get isMedium(): boolean {
    return this.isMediumSubject.value;
  }

  get isLarge(): boolean {
    return this.isLargeSubject.value;
  }

  get isXLarge(): boolean {
    return this.isXLargeSubject.value;
  }

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId) && this.document;
  }

  adaptDialogToScreen(dialog: MatDialogRef<any>, toggleClasses = 'full-screen-dialog') {
    this.isMobile$
      .pipe(distinctUntilChanged(), takeUntil(dialog.afterClosed()))
      .subscribe(isMobile => {
        isMobile ? dialog.addPanelClass(toggleClasses) : dialog.removePanelClass(toggleClasses)
      });
  }

  watchForTabClose(tabRef: Window) {
    // Watch for tab close (only way for Payfip to know when the user returns ...)
    return interval(300).pipe(
      map(_ => tabRef.closed),
      filter(closed => !!closed),
      take(1)
    );
  }
}
