import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnneeScolaire, CogitoClasse, CogitoEcole, CogitoInscPreIsnc, CogitoOptions, CogitoSecteurScolaire, ConfigCogito, MotifDerogation, NiveauScolaire, ParamLibre } from '@app/models/cogito';
import { Usager, UsagerChild } from '@app/models/consumer';
import { FamilyService, PlatformService, SnackbarService } from '@app/services';
import { CogitoService } from '@app/services/cogito.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import moment from 'moment';
import { DemarcheFormReponse } from '@app/models/demarche';

@Component({
  selector: 'app-edit-cogito-inscription',
  templateUrl: './edit-cogito-inscription.component.html',
  styleUrls: ['./edit-cogito-inscription.component.scss']
})
export class EditCogitoInscriptionComponent implements OnInit, OnDestroy {

  idFromUrl: number;
  config: ConfigCogito;
  options: CogitoOptions;
  isEdit: boolean = false;
  inscriptionsActive: boolean;
  saving: boolean = false;
  inscription: CogitoInscPreIsnc;
  dateToCalculate: Date;
  selectedChild: UsagerChild;
  selectedAnneeScolaire: AnneeScolaire;
  selectedNiveauScolaire: NiveauScolaire;
  selectedEcole: CogitoEcole;
  selectedSecteurScolaire: CogitoSecteurScolaire;
  listNiveauxScolairesAvailable: NiveauScolaire[];
  ageEnfantCalculateByAnneeScolaire: { years: number, months: number };
  listEcolesAvailable: CogitoEcole[];
  listSecteursScolaireAvailable: CogitoSecteurScolaire[];
  listClassesAvailable: CogitoClasse[];
  motifDerogationScolaire: MotifDerogation[];
  demandeDerogation: boolean = false;
  paramsLibres: ParamLibre[];
  libelleSecteurScolaireOrigine: string = "";
  isMobile: boolean;
  private onDestroy$ = new Subject<void>();

  @Input() fromDemarche: boolean;
  @Input() usager: Usager;
  @Output() save = new EventEmitter<DemarcheFormReponse>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private cogitoService: CogitoService,
    public platformService: PlatformService,
    private translate: TranslateService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    if (!this.fromDemarche) {
      this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    }

    this.loadData().subscribe(_ => {
      if (this.isEdit) {
        if (this.inscription._paramsLibres.length) {
          this.paramsLibres.forEach(confParamLibre => {
            let paramExist = this.inscription._paramsLibres.find(p => p.idParamLibre === confParamLibre.idParamLibre)
            if (paramExist) {
              confParamLibre.valeur = paramExist.valeur
              confParamLibre.idParamLibreValeur = paramExist.idParamLibreValeur ? paramExist.idParamLibreValeur : 0
            }
          })
        }

        if (this.inscription.derogationExist) {
          this.demandeDerogation = true;
          const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.inscription.cycleScolaireName, this.options);
          this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
        }

        this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.inscription.anneeScolaire.dateDebut);
        this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.inscription.dateNaissance);

        this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
          this.inscription.cycleScolaireName,
          this.options,
          this.config,
          this.demandeDerogation,
          this.inscription)

        this.listEcolesAvailable = this.cogitoService.globalFilterEcoles(
          this.inscription.secteurScolaire,
          this.inscription.cycleScolaireName,
          this.options,
          this.config,
          this.demandeDerogation,
          this.inscription)

        this.listClassesAvailable = this.globalFilterClasses(this.listEcolesAvailable, this.inscription.ecole)

        this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);
        this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.inscription, this.dateToCalculate);

        if (!this.config.enabledSelectNiveauScolaire) {
          this.listNiveauxScolairesAvailable = [...this.config.listNiveauxScolaires];
        }
      } else {
        this.inscription = {};
        if (!this.config.enabledSelectAnneeScolaire) {
          this.inscription.anneeScolaire = this.config.anneeScolaireByDefault;
          this.config.listAnneesScolaires = [];
          this.config.listAnneesScolaires.push(this.config.anneeScolaireByDefault)
          this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.inscription.anneeScolaire.dateDebut)

          if (this.options.childList) {
            this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.inscription, this.dateToCalculate)
          }
        }

        if (this.fromDemarche && this.usager && this.options?.childList) {
          this.selectedChild = this.options.childList.find(child => child.id === this.usager.id);
          this.childChanged(this.selectedChild.id);
        }
      }
    })
  }

  loadData() {
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        const loaders = [];

        loaders.push(this.cogitoService.getConfigForUser('cogitoInscription').pipe(tap((c: any) => {
          this.config = c.config;
          this.inscriptionsActive = this.cogitoService.checkPermInscription(this.config);
          this.paramsLibres = [...this.config.paramsLibres];
          this.paramsLibres = this.sortParamsLibresByOrdreAndFilterEnabled(this.paramsLibres)
        })));

        if (this.idFromUrl) {
          this.isEdit = true;

          // load edit inscription
          loaders.push(this.cogitoService.getInscription(family, this.idFromUrl).pipe(tap(p => {
            this.inscription = p;
          })));
        }

        loaders.push(this.cogitoService.getOptions(family, 'cogitoInscription').pipe(tap(o => {
          this.options = o;
        })));


        return forkJoin(loaders);
      })
    );
  }

  childChanged(idChild: number) {
    this.selectedChild = this.options.childList.find(child => child.id === idChild);
    this.inscription.idEnfant = this.selectedChild.id;
    if (this.selectedAnneeScolaire || this.inscription.anneeScolaire) {

      this.selectedAnneeScolaire = this.selectedAnneeScolaire ? this.selectedAnneeScolaire : this.inscription.anneeScolaire;
      this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
      this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);

      if (!this.config.enabledSelectNiveauScolaire) {
        this.inscription.niveauScolaire = this.listNiveauxScolairesAvailable.length ? this.listNiveauxScolairesAvailable[0].idNiveauScolaire : 0;
        this.niveauScolaireChanged(this.inscription?.niveauScolaire)
      }

      if (!this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.inscription.niveauScolaire)) {
        this.inscription.niveauScolaire = null;
        this.niveauScolaireChanged(null);
      }
    }
  }

  anneeScolaireChanged(value: AnneeScolaire) {
    this.selectedAnneeScolaire = this.config.listAnneesScolaires.find(annee => annee.idAnneeScolaire === value.idAnneeScolaire);
    this.inscription.anneeScolaire = this.selectedAnneeScolaire;

    this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.selectedAnneeScolaire.dateDebut);
    this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
    this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);

    if (!this.config.enabledSelectNiveauScolaire) {

      this.inscription.niveauScolaire = this.listNiveauxScolairesAvailable[0]?.idNiveauScolaire;
      this.niveauScolaireChanged(this.inscription.niveauScolaire)

    } else if (this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.inscription.niveauScolaire)) {

      this.niveauScolaireChanged(this.inscription.niveauScolaire)

    } else {

      this.inscription.niveauScolaire = null;
      this.niveauScolaireChanged(null);

    }

    if (this.options.childList) {
      this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.inscription, this.dateToCalculate);
    }

  }

  niveauScolaireChanged(idNiveauScolaire: Number) {
    this.selectedNiveauScolaire = this.config.listNiveauxScolaires.find(niveau => niveau.idNiveauScolaire === idNiveauScolaire);

    this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
      this.selectedNiveauScolaire?.cycleScolaire,
      this.options,
      this.config,
      this.demandeDerogation,
      this.inscription);

    if (this.demandeDerogation) {
      const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.selectedNiveauScolaire?.cycleScolaire, this.options);
      this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
    }

    if (this.config.enabledDerogationObligatoire && !this.demandeDerogation) {
      this.inscription.secteurScolaire = this.listSecteursScolaireAvailable[0]?.idSecteurScolaire;
      this.secteurScolaireChanged(this.inscription.secteurScolaire);

    } else if (this.listSecteursScolaireAvailable.find(secteur => secteur.idSecteurScolaire === this.inscription.secteurScolaire)) {

      this.secteurScolaireChanged(this.inscription.secteurScolaire);

    } else {

      this.inscription.secteurScolaire = null;
      this.secteurScolaireChanged(null);

    }
  }

  secteurScolaireChanged(value: number) {
    this.listEcolesAvailable = this.cogitoService.globalFilterEcoles(
      value,
      this.selectedNiveauScolaire?.cycleScolaire || this.inscription.cycleScolaireName,
      this.options,
      this.config,
      this.demandeDerogation,
      this.inscription);

    if (this.listEcolesAvailable.find(ecole => ecole.idEcole === this.inscription.ecole)) {
      this.ecoleChanged(this.inscription.ecole);
    } else {
      this.inscription.ecole = null;
      this.ecoleChanged(null);
    }
  }

  ecoleChanged(idEcole: number) {
    this.inscription.classe = null;
    this.selectedEcole = this.listEcolesAvailable.find(ecole => ecole.idEcole === idEcole);

    if (this.selectedEcole) {
      this.listClassesAvailable = this.filtreClassByAnneeScolaireAndNiveauScolaire(this.selectedEcole.tabClasse);
    }
  }

  globalFilterClasses(listEcole: CogitoEcole[], idEcole: number) {
    let ecole = listEcole.find(ecole => ecole.idEcole === idEcole)
    return this.filtreClassByAnneeScolaireAndNiveauScolaire(ecole.tabClasse);
  }

  filtreClassByAnneeScolaireAndNiveauScolaire(tabClass: CogitoClasse[]) {
    return tabClass.filter(classe => {
      let niveauScolaireExiste = classe.tabNiveauScolaire.find(niveauSco => niveauSco.idNiveauScolaire === this.selectedNiveauScolaire?.idNiveauScolaire || niveauSco.idNiveauScolaire === this.inscription.niveauScolaire);
      let anneeScolaireExiste = classe.tabAnneeScolaire.find(anneeSco => anneeSco.idAnneeScolaire === this.selectedAnneeScolaire?.idAnneeScolaire || anneeSco.idAnneeScolaire === this.inscription.anneeScolaire.idAnneeScolaire);
      return niveauScolaireExiste && anneeScolaireExiste;
    })
  }

  sortParamsLibresByOrdreAndFilterEnabled(paramsLibres: ParamLibre[]): ParamLibre[] {
    return paramsLibres.sort((a: ParamLibre, b: ParamLibre) => {
      if (a.ordre < b.ordre) return -1;
      if (a.ordre > b.ordre) return 1;
      return 0;
    }).filter(param => param.enabled);
  }

  setDisplayDateToCalculate(dateToCalculate: Date): string {
    return moment(dateToCalculate).format('DD/MM/YYYY');
  }

  compareAnneeFn(annee1: AnneeScolaire, annee2: AnneeScolaire) {
    return annee1 && annee2 ? annee1.idAnneeScolaire === annee2.idAnneeScolaire : annee1 === annee2;
  }

  checkParamsLibres(): boolean {
    return this.paramsLibres
      .filter(paramLib => paramLib.obligatoire)
      .every(paramLibre => paramLibre.valeur || paramLibre.idParamLibreValeur);
  }

  onClickValidateInscription() {
    let inscription = this.mapToSave(this.inscription);
    const family = this.familyService.currentFamily;

    this.saving = true;
    this.cogitoService.saveInscription(inscription, family?.id).pipe(
      finalize(() => this.saving = false)
    ).subscribe((res: any) => {
      if (!res.errors) {
        this.snackbar.info('info.success.saving');
        if (this.fromDemarche) {
          let inscriptionData = { id: res.id, type: 'Inscription', usager: this.usager };
          this.save.emit(inscriptionData);
        } else {
          this.router.navigate(['/account/cogito-inscriptions']);
        }
      } else {
        res.errors.forEach((err: { message: string }) => {
          let message = err.message as string;

          if (message === 'cogito.error.inscriptionOnSameAnnee' || message === 'cogito.error.inscriptionOnSameAnneeAndSchool') {
            message = this.translate.instant(message);
            this.snackbar.error(message);
          }
        })
      }
    });
  }

  onClickDemandeDerogation() {
    this.demandeDerogation = !this.demandeDerogation;

    this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
      this.selectedNiveauScolaire?.cycleScolaire,
      this.options,
      this.config,
      this.demandeDerogation,
      this.inscription);

    if (!this.demandeDerogation) {
      this.inscription.idMotifDerogation = null;
      this.inscription.secteurScolaire = this.listSecteursScolaireAvailable[0]?.idSecteurScolaire;
      this.secteurScolaireChanged(this.inscription.secteurScolaire);
    } else {
      const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.selectedNiveauScolaire?.cycleScolaire, this.options);
      this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
      this.inscription.secteurScolaire = null;
      this.secteurScolaireChanged(null);
    }
  }

  mapToSave(data: CogitoInscPreIsnc): any {

    let dataToSave: any = {};
    dataToSave._idEnfant = data.idEnfant;
    dataToSave._idAnneeScolaire = data.anneeScolaire.idAnneeScolaire;
    dataToSave._idNiveauScolaire = data.niveauScolaire;
    dataToSave._idSecteurScolaire = data.secteurScolaire;
    dataToSave._idEcole = data.ecole;
    dataToSave._idClasse = data.classe;
    dataToSave.idInscriptionCogito = data.idInscriptionCogito;
    dataToSave.idMotifDerogation = data.idMotifDerogation;
    dataToSave.isRedoublement = data.isRedoublement;
    dataToSave.derogationExist = this.demandeDerogation ? true : false;
    dataToSave._paramsLibres = this.paramsLibres.map(param => {
      if (param.nature === "Liste") {
        return { idParamLibre: param.idParamLibre, idParamLibreValeur: param.idParamLibreValeur, nature: param.nature }
      }
      return { idParamLibre: param.idParamLibre, valeur: param.valeur, nature: param.nature }
    });

    return dataToSave;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
