<!-- <ng-container *ngIf="actions">
  <ng-container *ngIf="forceCompact || ((platformService.isMobile$ | async) && actions?.length > 1); else inlineButtons">
    <button mat-raised-button [matMenuTriggerFor]="actionsMenu" color="accent">
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #actionsMenu="matMenu">
      <button *ngFor="let action of actions" mat-menu-item [matTooltip]="action.details|trans"
        (click)="onClickAction(action)">
        <ng-container *ngTemplateOutlet="buttonContent; context: {action: action}"></ng-container>
      </button>
    </mat-menu>
  </ng-container>

  <ng-template #inlineButtons>
    <button *ngFor="let action of actions" mat-raised-button [color]="action.color" [matTooltip]="action.details|trans"
      (click)="onClickAction(action)">
      <ng-container *ngTemplateOutlet="buttonContent; context: {action: action}"></ng-container>
    </button>
  </ng-template>
</ng-container> -->

<ng-template #buttonContent let-action="action">
  <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
  {{ action.title|trans }}
</ng-template>

<div style="margin:0 5px 3px 0;">
  <ng-content></ng-content>
</div>

<ng-container #placeholder></ng-container>