<div class="board-container" *ngIf="!isSmall && !compact">

  <ng-container *ngFor="let card of boardCards">
    <div *ngIf="!!card.disabledCause" class="flex-{{card.width}}p" [matTooltip]="card.disabledCause|trans">
      <ng-container *ngTemplateOutlet="cardTemplate; context: {card: card}"></ng-container>
    </div>
    <ng-container *ngIf="!card.disabledCause" [ngTemplateOutlet]="cardTemplate"
      [ngTemplateOutletContext]="{card: card}"></ng-container>
  </ng-container>

</div>

<ng-template #cardTemplate let-card="card">
  <mat-card [portailCustomStyle]="card.contentStyle" (click)="onClickCard(card)" class="flex-{{card.width}}p"
    [ngClass]="{'card': !!card.component, 'disabled': !!card.disabledCause}">
    <mat-card-header class="header-card" [portailCustomStyle]="card.titleStyle"
      [include]="['borderRadius','backgroundColor']" [hidden]="!card.title">

      <mat-card-title [portailCustomStyle]="card.titleStyle" [include]="headerStyleParts">
        <span>
          <i *ngIf="card.iconDomino" class="icodomino-{{ card.iconDomino }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && card.typeIcon" class="{{card.typeIcon}} fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && !card.typeIcon" class="fas fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
        </span>
        <span [portailCustomStyle]="card.titleStyle" [exclude]="headerStyleParts"
          style="padding-inline: 10px; width: 100%;">
          {{ card.title }}
        </span>
        <mat-icon *ngIf="card.component && !card.disabledCause" class="icon-double-arrow"
          [style.color]="card.titleStyle?.color">
          keyboard_arrow_right
        </mat-icon>
      </mat-card-title>


    </mat-card-header>

    <mat-card-content *ngIf="card.content" class="dashboard-card-content">
      <div [innerHTML]="card.content|dynamic:variables|safe"></div>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- VERSION MOBILE -->
<ng-container *ngIf="isSmall || compact">
  <div *ngFor="let card of boardCards" style="width: 99%; margin: auto; margin-top: 5px; margin-bottom: 5px;">

    <button *ngIf="card.component" mat-raised-button (click)="onClickCard(card)" [portailCustomStyle]="card.titleStyle"
      [include]="headerStyleParts" class="mobile">
      <div class="fx-direction-row fx-start-center">
        <i *ngIf="card.iconDomino" class="icodomino-{{ card.iconDomino }}"
          [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
        <i *ngIf="card.iconFontawesome && card.typeIcon" class="{{card.typeIcon}} fa-{{ card.iconFontawesome }}"
          [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
        <i *ngIf="card.iconFontawesome && !card.typeIcon" class="fas fa-{{ card.iconFontawesome }}"
          [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>

        <span [portailCustomStyle]="card.titleStyle" [exclude]="headerStyleParts"
          style="padding-inline: 5px; width: 100%; line-height: normal;">{{card.title}}</span>

        <mat-icon [ngStyle]="{'color': card.titleStyle?.color , 'margin-right': '5px'} ">
          keyboard_arrow_right
        </mat-icon>
      </div>
    </button>

    <mat-expansion-panel *ngIf="!card.component && card.title" [portailCustomStyle]="card.contentStyle"
      style="height: auto;">
      <mat-expansion-panel-header [portailCustomStyle]="card.titleStyle" [include]="['backgroundColor']"
        style="padding: 0 20px 0 5px">
        <mat-panel-title [portailCustomStyle]="card.titleStyle" [include]="headerStyleParts"
          class="fx-direction-row fx-start-center">
          <i *ngIf="card.iconDomino" class="icodomino-{{ card.iconDomino }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && card.typeIcon" class="{{card.typeIcon}} fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && !card.typeIcon" class="fas fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <span [portailCustomStyle]="card.titleStyle" [exclude]="headerStyleParts"
            style="padding-inline: 5px; width: 100%;">{{card.title}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHTML]="card.content|dynamic:variables|safe" class="dashboard-card-content"></div>
    </mat-expansion-panel>

    <mat-card *ngIf="!card.component && !card.title" [portailCustomStyle]="card.contentStyle" class="card-mobile">
      <mat-card-content>
        <div [innerHTML]="card.content|dynamic:variables|safe" class="dashboard-card-content"></div>
      </mat-card-content>
    </mat-card>

  </div>
</ng-container>