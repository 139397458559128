<mat-drawer-container autosize>
  <mat-drawer-content style="width:100%;">

    <ng-template appTopbarActions>
      <button mat-mini-fab color="accent" (click)="toggleOptions()" matTooltip="Options d'affichage">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-template>

    <div class="conteneur thin-scrollbar" *ngIf="isLoaded; else loader">

      <mat-card *ngIf="config.infoMessageList" style="margin: 5px;" [portailCustomStyle]="config.styleCardInfoList">
        <div [innerHtml]="config.infoMessageList | safe"></div>
      </mat-card>

      <div class="inscription-list">
        <ng-container *ngFor="let inscription of inscriptionsFiltered">
          <mat-card class="inscription-card" [ngStyle]="{'opacity': inscription.estExpire ? '0.7' : '1'}"
            *ngIf="showExpiredInscriptions || !inscription.estExpire">
            <mat-card-header class="card-header fx-direction-row fx-between-start">
              <mat-card-title class="fx-direction-row fx-start-center">
                <app-icon style="margin-right: 5px;" type="do" icon="inscription">
                </app-icon>
                <span>{{ inscription.nom }} {{inscription.prenom}}</span>
              </mat-card-title>
              <mat-card-subtitle style="margin-left: 30px;">
                <ng-container *ngIf="inscription.dateDebut && inscription.dateDebut != '0000-00-00'">
                  <span *ngIf="inscription.dateFin && inscription.dateFin != '0000-00-00'; else fromDate">
                    Du {{ inscription.dateDebut|date:'shortDate' }} au {{ inscription.dateFin|date:'shortDate' }}
                  </span>
                  <ng-template #fromDate>
                    <span>À partir du {{ inscription.dateDebut|date:'shortDate' }}</span>
                  </ng-template>
                </ng-container>
              </mat-card-subtitle>
              <button *ngIf="permToEdit && !inscription.estExpire" mat-mini-fab color="primary"
                [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-icon *ngIf="inscription.estExpire" class="fx-alignSelf-start" matTooltip="Expiré">lock_clock
              </mat-icon>
            </mat-card-header>

            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="onClickEdit(inscription)">
                <mat-icon color="primary">edit
                </mat-icon>
                {{ 'diabolo.btn_edit'|trans }}
              </button>
              <button *ngIf="inscription.idInscriptionDiabolo < 0" mat-menu-item
                (click)="onClickDelete(inscription.idInscriptionDiabolo)">
                <mat-icon color="warn">delete
                </mat-icon>
                {{ 'action.delete'|trans }}
              </button>

            </mat-menu>

            <mat-card-content>
              <div *ngFor="let periode of inscription._periodes" style="margin-bottom: 5px;">
                <div *ngIf="showDisabledPeriods || periode.active" class="fx-direction-row fx-between-start"
                  style="border: 1px solid black; border-radius: 5px; margin:4px; padding: 4px;">
                  <div>
                    <span><i class="icon icodomino-etablissement"
                        style="margin-right: 5px;"></i>{{periode.etablissement.designation}}<br></span>
                    <span *ngIf="!config.notShowAccueils" style="margin-left: 10px;"><i class="icon icodomino-accueil"
                        style="margin-right: 5px;"></i>{{periode.accueil.designation}}<br></span>
                    <span style="margin-left: 20px;"><i class="icon icodomino-periode"
                        style="margin-right: 5px;"></i>{{periode.designation}}</span>
                  </div>
                  <mat-icon *ngIf="!periode.isNewPeriode" [matTooltip]="periode.active ? 'Active' : 'Inactive'"
                    [color]="periode.active ? 'primary' : 'warn'">
                    {{periode.active ? 'check_circle' : 'block'}}
                  </mat-icon>
                  <mat-icon *ngIf="periode.isNewPeriode" matTooltip="En Attente" color="accent">
                    hourglass_top
                  </mat-icon>
                </div>
              </div>

              <div *ngIf="inscription.idInscription <= 0" class="fx-direction-row fx-end-center"
                style="margin-top: 15px;">
                <em>En attente de validation.</em>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>

      <mat-card *ngIf="permToCreate && createInscriptionsActive" class="actions-card card-add">
        <mat-card-header class="card-header fx-direction-row fx-center-center">
          <mat-card-title class="fx-direction-row fx-center-center">
            {{ 'diabolo.new_inscription'|trans }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 20px;" class="fx-direction-row fx-center-center">
          <button mat-fab color="primary" matTooltip="{{ 'diabolo.new_inscription'|trans }}" [routerLink]="addBtnUrl"
            [disabled]="!addBtnUrl">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!inscriptions?.length && !permToCreate">
        <h2 style="text-align: center;">{{ 'diabolo.no_inscription'|trans }}</h2>
      </div>

    </div>

  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <mat-form-field class="no-hint" style="width: 100%; margin-top: 4px;">
      <mat-label>Filtrer par usager</mat-label>
      <mat-select [(ngModel)]="filterUsagers" (ngModelChange)="changeSelectUser($event)" multiple>
        <mat-option *ngFor="let usager of listUsagers" [value]="usager">
          {{usager.nom}} {{usager.prenom}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="fx-direction-col" style="margin: 5px; padding: 5px;">
      <mat-slide-toggle [(ngModel)]="showDisabledPeriods" class="slideToggle">
        <span class="fx-direction-row fx-center-center">{{'diabolo.filtre_periodes_inactives' | trans}}
          (<mat-icon color="warn">block</mat-icon>)</span>
      </mat-slide-toggle>

      <mat-slide-toggle [(ngModel)]="showExpiredInscriptions" class="slideToggle">
        <span class="fx-direction-row fx-center-center">{{'diabolo.filtre_dossiers_inscriptions_expires' | trans}}
          (<mat-icon color="primary">lock_clock</mat-icon>)</span>
      </mat-slide-toggle>
    </div>

  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>