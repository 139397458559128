<app-dialog-header title="Photo de {{child.prenom}} {{child.nom}}"></app-dialog-header>

<div mat-dialog-content class="thin-scrollbar">
  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload accept="image/*" />
  <div class="fx-direction-col fx-start-center">
    <div class="container-btn">
      <button mat-raised-button class="upload-btn" color="primary" (click)="$event.preventDefault();fileUpload.click()"
        [disabled]="!loadedGedConfig">
        <mat-icon>attach_file</mat-icon>
        Sélectionner un fichier
      </button>
    </div>
    <div class="fx-direction-col fx-center-center" style="margin-bottom: 10px;">
      <div *ngIf="!previewURL" class="no-preview fx-direction-row fx-center-center"
        [ngStyle]="{'width': isMobile ? '300px' : '350px'}">
        <app-loader *ngIf="loadingPhoto">Chargement de la photo...</app-loader>
        <app-icon *ngIf="!loadingPhoto" type="far" icon="file-image" size="80" style="width: 60px;">
        </app-icon>
      </div>
      <ng-container [ngTemplateOutlet]="filePreview" [ngTemplateOutletContext]="{extension: getExtension(fileName) }">
      </ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions align="center">
  <div *ngIf="errorMessage; else btnAction" class="fx-direction-row fx-start-center" style="margin-bottom: 10px;">
    <mat-icon color="warn">warning</mat-icon>
    <span color="warn" class="file-err">{{errorMessage}}</span>
  </div>
  <ng-template #btnAction>
    <button mat-raised-button color="warn" [mat-dialog-close]>
      Annuler
    </button>
    <button mat-raised-button color="primary" (click)="savePhoto()"
      [disabled]="!file || !previewURL || uploadProgress || loading">
      valider
    </button>
  </ng-template>
</div>


<ng-template #filePreview let-extension="extension">
  <div *ngIf="previewURL">
    <img *ngIf="extension !=='pdf'" [src]="previewURL" class="image-viewer"
      [ngStyle]="{'max-width': isMobile ? '300px' : '350px'}">
  </div>

  <div *ngIf="fileName !== ''" class="uploading-file-name">
    <span>{{ fileName }}</span>
  </div>

  <mat-progress-bar *ngIf="uploadProgress !== null" [value]="uploadProgress"></mat-progress-bar>
</ng-template>