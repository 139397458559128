import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DemarcheConfig, DemarcheItem } from '@app/models/demarche';
import { of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DemarcheService {

  configUrl = 'conf/demarche';
  cachedConfigDemarches: DemarcheConfig | null = null;

  constructor(
    private http: HttpClient
  ) { }

  getConfig(adminMode: boolean = false) {

    if (this.cachedConfigDemarches && !adminMode) {
      return of(this.cachedConfigDemarches);
    }

    return this.http.get<DemarcheConfig>(this.configUrl)
      .pipe(tap(conf => this.cachedConfigDemarches = conf));
  }

  updateConfig(config: DemarcheConfig | any) {
    this.cachedConfigDemarches = null;
    return this.http.put(this.configUrl, { config });
  }

  getDemarcheById(id: number) {
    if (this.cachedConfigDemarches) {
      return of(this.cachedConfigDemarches).pipe(map(
        (conf: DemarcheConfig) => conf.demarches.find(
          (demarche: DemarcheItem) => demarche.id == id && demarche.enabled)
      ))
    }

    return this.getConfig().pipe(
      map(
        (conf: DemarcheConfig) => conf.demarches.find(
          (demarche: DemarcheItem) => demarche.id == id && demarche.enabled)
      )
    )
  }
}


//TODO AJouter une fonction dans le service des démarches pour renvoyer seuelemnt l'url de la démarche pour les btn add des formulaires
// this.demarcheService.getConfig().subscribe(confDemarche => {
//   this.demarche = confDemarche.demarches[2];
//   this.urlToAddChild = this.demarche ? `/account/demarches/${this.demarche.id}` : "/account/children/add";

// });