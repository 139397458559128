import { Component, Inject, OnInit, TemplateRef, ViewChild, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { FieldArrayType, FieldType } from '@ngx-formly/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PlatformService } from '@app/services/platform.service';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions, ExtendedFormlyTemplateOptions } from '@app/forms/config/form-model';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, FamilyService } from '@app/services';
import { accessProperty, clone } from '@app/utils/object';
import { DataTableTypeDialogComponent } from './datatable.dialog.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormConfigService } from '@app/services/form-config.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-formly-data-table',
  templateUrl: './datatable.type.component.html',
  styleUrls: ['./datatable.type.component.scss']
})

export class DataTableTypeComponent extends FieldArrayType implements OnInit, OnDestroy {

  @ViewChild('suggestionDialog', { static: true }) suggestionDialogTemplate: TemplateRef<any>;

  field: ExtendedFormlyFieldConfig;
  options: ExtendedFormlyFormOptions;
  to: ExtendedFormlyTemplateOptions;

  suggestedItems: any[];
  suggestedItemsFiltered: any[];
  suggestions$ = new BehaviorSubject([]);

  isMobile: boolean;

  private onDestroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public platformService: PlatformService,
    protected http: HttpClient,
    private authenticationService: AuthenticationService,
    private familyService: FamilyService,
    private formConfigService: FormConfigService,
  ) {
    super();
  }

  ngOnInit() {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((value) => {
      this.isMobile = value;
    });

    if (!this.options.formState.disabled && !this.field.readOnly) {
      this.suggestedItems = [];
      if (this.to.suggestedItemsUrl) {

        // For sharing ressource from 'suggestedItemsUrl' by options.formState
        this.options.formState.suggestions = this.options.formState.suggestions || {};

        if (!this.options.formState.suggestions[this.to.suggestedItemsUrl]) {

          this.options.formState.suggestions[this.to.suggestedItemsUrl] = new BehaviorSubject([]);

          if (!this.options.adminMode) {
            const idAdulte: string = this.authenticationService.currentUserValue.idAdulte.toString();
            const idFamille: string = this.familyService.currentFamily.id.toString();

            const url = this.to.suggestedItemsUrl
              .replace('#idAdulte#', idAdulte)
              .replace('#idFamille#', idFamille);

            this.http.get<any[]>(url).subscribe(result => {
              this.options.formState.suggestions[this.to.suggestedItemsUrl].next(result);
            });
          } else {
            // On peut mettre des exemples pour la page admin dans defaultValue...
            this.options.formState.suggestions[this.to.suggestedItemsUrl].next(clone(this.field.defaultValue));
          }

        }

        this.options.formState.suggestions[this.to.suggestedItemsUrl]
          .subscribe(suggestions => {

            this.suggestedItems = suggestions;

            this.initLienFamilleSuggestionList(this.field.fieldGroup, "fieldGroup")
            this.initLienFamilleSuggestionList(this.field.fieldArray.fieldGroup, "fieldArray")

            // update other list for suggestion update
            this.model.forEach((model, index) => {
              const i = this.suggestedItems.findIndex(item => (model.id === item.id));
              this.model[index] = (i >= 0) ? clone(this.suggestedItems[i]) : model;
            });

            this.applyModelToFormValue(true);

          });
      }
    }

    if (!this.options.adminMode) {
      this.field.defaultValue = [];
    } else {
      // pour avoir des exemples dans la liste des suggestion,
      // on en met 4 dans defaultValue, et on n'en garde que 2 ici
      while (this.model.length > 2) {
        this.remove(this.model.length - 1);
      }
    }

  }

  initLienFamilleSuggestionList(fieldGroup: any[], fieldType: string) {
    let fieldLienFamille: ExtendedFormlyFieldConfig;
    fieldGroup.forEach(item => {
      if (fieldType === "fieldArray") {
        fieldLienFamille = this.formConfigService.findFieldByName(item.fieldGroup, 'lienFamille')
      }
      if (fieldType === "fieldGroup") {
        item.fieldGroup.forEach(el => {
          fieldLienFamille = this.formConfigService.findFieldByName(el.fieldGroup, 'lienFamille')
        })
      }

      if (fieldLienFamille && fieldLienFamille.type === "select") {
        let options = (fieldLienFamille.templateOptions.options as Array<any>);
        this.suggestedItems.forEach(suggestedItem => {
          if (!options.find(opt => opt.value === suggestedItem.lienFamille)) {
            suggestedItem.lienFamille = null;
          }
        })
      }
    })
  }

  getColValue(item, colDefKey) {
    let value = accessProperty(item.model, colDefKey);

    const field = this.formConfigService.findFieldByName(item.fieldGroup, colDefKey);

    if (field && field.type === 'select' && field.templateOptions && field.templateOptions.options) {
      const options = (field.templateOptions.options as Array<any>);

      value = options.find(opt => opt.value === value) ? options.find(opt => opt.value === value).label : '';

      // if (item.model.lienFamille && !value) {
      //   item.formControl.controls.lienFamille.reset();
      // }

    }

    return value;
  }

  onAddClick() {

    if (this.field.readOnly) {
      return;
    }

    this.suggestedItemsFiltered = this.suggestedItems.filter(a => (
      this.model.findIndex(b => a.id === b.id) < 0
    )).map(item => {
      item.checked = false;
      return item;
    });

    if (this.suggestedItemsFiltered.length > 0) {
      this.openSuggesionDialog();
    } else {
      this.openEditDialog(null, -1);
    }
  }

  onRemoveClick(i) {

    if (this.field.readOnly) {
      return;
    }

    let itemLabel = 'Cet élément';

    if (this.to.columns) {
      itemLabel = this.to.columns.map(col =>
        `<span class="${col.className}"> ${this.getColValue(this.field.fieldGroup[i], col.key)} </span>`)
        .join(' ');
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: `Supprimer ${itemLabel} ?` }
    });

    dialogRef.afterClosed().subscribe(res => res ? this.remove(i) : null);
  }

  openEditDialog(item, i): void {
    let title = 'Modifier ' + this.to.itemLabel;
    if (item === null) {
      item = { fieldGroup: clone(this.field.fieldArray.fieldGroup), model: {}, formControl: {} };
      title = 'Ajouter ' + this.to.itemLabel;
    }

    const data = {
      field: item,
      options: this.options,
      title,
      readOnly: this.field.readOnly
    };

    // tslint:disable-next-line: no-use-before-declare
    this.openDialog(DataTableTypeDialogComponent, data, (result => {
      if (result) {
        if (i >= 0 && i < this.field.fieldGroup.length) {
          this.model[i] = result;
        } else {
          // find an id for the new row (important for suggestion list)
          // and transform it in negative to not confuse it with item already in DB
          if (this.to.suggestedItemsUrl) {
            result.id = ((this.suggestedItems.map(it => it.id)
              .reduce((acc, cur) => Math.max(Math.abs(acc), Math.abs(cur)), 1)) + 1) * -1;
          } else {
            result.id = (this.model.length + 1) * -1;
          }

          this.add(null, result);
        }
        this.applyModelToFormValue();
      }
    }));
  }

  openSuggesionDialog() {
    this.openDialog(this.suggestionDialogTemplate, {});
  }

  selectionIsValid() {
    return this.suggestedItemsFiltered.filter(item => item.checked).length > 0;
  }

  validateSelection() {

    this.suggestedItemsFiltered.filter(item => item.checked).forEach(item => {
      this.add(null, item);
    });
    this.applyModelToFormValue();
  }

  applyModelToFormValue(fromSuggestion = false) {
    const values = {};
    values[this.field.key as string] = this.model;
    this.form.patchValue(values);
    this.form.controls[this.field.key as string].markAsTouched();

    if (!fromSuggestion) {
      this.updateSuggestions();
    }
  }

  openDialog(componentOrTemplateRef, data, callback?): void {

    const dialConf: MatDialogConfig = {
      data,
      closeOnNavigation: true,
      maxWidth: this.options.containerWidth ? this.options.containerWidth : '600px'
    };

    if (!this.options.adminMode && this.isMobile) {
      dialConf.panelClass = 'full-screen-dialog';
    }

    if (this.options.adminMode && this.options.containerWidth <= 400) {
      dialConf.panelClass = 'full-screen-dialog-admin-mode';
      dialConf.position = { right: '0' };
    }

    const dialogRef = this.dialog.open(componentOrTemplateRef, dialConf);
    dialogRef.afterClosed().subscribe(callback);
  }

  updateSuggestions() {

    const suggestions = this.model.concat(
      this.suggestedItems.filter(itemA => this.model.findIndex(itemB => itemA.id === itemB.id) < 0)
    );

    if (this.to.suggestedItemsUrl) {
      this.options.formState.suggestions[this.to.suggestedItemsUrl].next(suggestions);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}


