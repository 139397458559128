import { Component, OnInit, OnDestroy } from '@angular/core';
import { BoardConfigService, UserService, FamilyService, FormConfigService, BaseConfigService } from '@app/services';
import { PermissionService } from '@app/services/permission.service';
import { Router } from '@angular/router';
import { Adulte } from '@app/models/adulte';
import { Subject, forkJoin } from 'rxjs';
import { filter, take, takeUntil, tap, switchMap } from 'rxjs/operators';
import { StepType } from '@app/forms/config/form-model';
import { AdresseUpdateService } from '@app/services/adresse-update.service';
import { MandatSepa } from '@app/models/mandat-sepa';
import { MandatSepaService } from '@app/services/mandat-sepa.service';
import { BoardCard } from '@app/models/board-card';

@Component({
  selector: 'app-foyer',
  templateUrl: './foyer.component.html',
  styleUrls: ['./foyer.component.scss']
})
export class FoyerComponent implements OnInit, OnDestroy {

  // Data
  userData: Adulte;
  infosFoyer: any;
  conjoint: Adulte; // {};
  id: number;
  mandatsSepa: MandatSepa[];

  // Step names (for button DropDown)
  adulteFormSteps: any[];
  foyerFormSteps: any[];
  listKeysFieldForm: any[] = [];

  // Permission
  editFoyer: boolean;
  showMandatSepaCard: boolean;

  addBtnUrl: string;

  onDestroy$ = new Subject();

  constructor(
    private router: Router,
    private familyService: FamilyService,
    private formConfigService: FormConfigService,
    private userService: UserService,
    private permService: PermissionService,
    private addressService: AdresseUpdateService,
    private mandatService: MandatSepaService,
    private baseConfigService: BaseConfigService
  ) { }

  ngOnInit() {
    this.editFoyer = this.permService.getPermissionSettings().foyer_edit && this.familyService.currentFamily?.active;
    this.id = (this.familyService.currentFamily ? this.familyService.currentFamily.id : null) || null;
    this.showMandatSepaCard = this.permService.hasPermission('mandat_sepa_display_foyer') && this.permService.hasPermission('mandat_sepa_access');

    this.baseConfigService.getFirstConf$("board-user")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conf => {
        const cards = conf.content.cards;
        const idDemarche = cards.find((card: BoardCard) => card.component === 'foyer').addBtnDemarcheId;
        this.addBtnUrl = idDemarche ? `/account/conjoint/add/${idDemarche}` : '/account/conjoint/edit';
      })

    this.loadForms();
    this.loadUserData();
    // Update family data every time user switches family
    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.mandatsSepa = null; })
    ).subscribe(f => {
      this.reloadFoyerData(f.id);
      if (this.showMandatSepaCard) {
        this.mandatService.getFamilyMandatsSepa(f.id).subscribe((data: any) => this.mandatsSepa = data);
      } else {
        this.mandatsSepa = []
      }
    });

    this.addressService.clickToUpdateAddress$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(value => {
      if (value === true) {
        this.loadForms();
        this.loadUserData();
        this.reloadFoyerData(this.id);
      }
    })
  }

  loadUserData() {
    // could use userService.currentAdulte, but as we want to refresh data from backend
    this.userService.getCurrentUserData().subscribe((data: Adulte) => this.userData = data);
  }

  reloadFoyerData(id: number) {
    this.infosFoyer = null;
    this.conjoint = null;

    this.familyService.getFamilyData(id).subscribe(data => {
      this.infosFoyer = data.famille;
      this.conjoint = data.conjoint;
    });
  }

  loadForms() {
    this.formConfigService.getForm('form-famille').subscribe(form => {
      this.foyerFormSteps = this.getFormattedSteps(form, ['validation']);
      this.listKeysFieldForm = this.formConfigService.getKeysListOfFormSteps(form);
    });
    this.formConfigService.getForm('form-adulte').subscribe(form => this.adulteFormSteps = this.getFormattedSteps(form, ['validation']));
  }

  getFormattedSteps(steps: StepType[], excludedNames: string[]) {
    return steps
      .filter(step => !excludedNames || !excludedNames.includes(step.stepName))
      .map(step => ({ stepName: step.stepName, label: step.label }));
  }

  openForm(entityFormPath, stepName) {
    const path = stepName ? `/account/${entityFormPath}/${stepName}` : `/account/${entityFormPath}/edit`;
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
