import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { LinkService } from '@app/services/link.service';
import { Link, Category, CustomLinkItem } from '@app/models/custom-link';
import { SnackbarService, TypeSnackbar } from '@app/services/snackbar.service';
import { CustomStyleDirective } from '@app/directives/custom-style.directive';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService, PlatformService, MenuService, FamilyService } from '@app/services';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-menu-custom-links',
  templateUrl: './custom-links.component.html',
  styleUrls: ['./custom-links.component.scss']
})
export class CustomLinksComponent implements OnInit {

  @Input() previewMode = false;
  @Input() linkItems: CustomLinkItem[];
  boardLink: Link[];
  hovering: Link;
  accountVars: any;
  destroy$ = new Subject();
  isMobile: boolean;

  constructor(
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private router: Router,
    public linkService: LinkService,
    public authenticationService: AuthenticationService,
    public platformService: PlatformService,
    private menuService: MenuService,
    private familyService: FamilyService
  ) { }

  ngOnInit() {

    this.platformService.isMobile$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((value) => {
      this.isMobile = value;
    });

    if (!this.previewMode) {
      if (!this.authenticationService.hasRole("Admin") && this.authenticationService.isAuthenticated) {
        this.familyService.currentFamilyChange$.pipe(
          takeUntil(this.destroy$),
          switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
        ).subscribe((vars: any) => {
          this.accountVars = vars;
        });
      }

      this.linkService.initLinks();

    }


  }

  isUserIsAuthenticated() {
    return !this.authenticationService.hasRole("Admin") && this.authenticationService.isAuthenticated
  }

  showToUser(item: CustomLinkItem) {
    const logged = this.authenticationService.isAuthenticated;
    return !item.limited || (item.limited === 'login' && logged) || (item.limited === 'logout' && !logged);
  }

  onCustomLinkClick(link: Link, event: MouseEvent) {
    event.preventDefault();

    if (this.isMobile) {
      this.menuService.sidenav.close();
    }

    if (this.previewMode) {
      this.snackbarService.open({
        message: 'Lien inactif pendant la configuration',
        type: TypeSnackbar.info,
        textButton: 'Ok'
      });

      return false;
    }
    // @TODO: implement a 404 page, it could become more usual, because of this ..
    if (link.urlApiDoc) {
      window.open(environment.apiUrl + link.urlApiDoc, link.target);
    } else if (link.url) {
      window.open(link.url, link.target);
    } else if (link.urlNewsCat) {
      this.router.navigate([link.urlNewsCat]);
    } else if (link.urlContent) {
      this.router.navigate([link.urlContent]);
    }
  }

  downloadFileFromDialog(link: Link) {
    window.open(environment.apiUrl + link.url, '_self');
  }

  getCatTitleStyle(category: Category) {
    // Only extract style wanted for title (no border / bg)
    return { ...category.style, ...{ backgroundColor: '', borderColor: '' } };
  }

  getCatBodyStyle(category: Category) {
    if (!category.style) {
      return {};
    }
    // Only extract style wanted for title (border & bg)
    return {
      ...{ backgroundColor: category.style.backgroundColor, borderColor: category.style.borderColor },
      ...category.style.borderColor ? { borderWidth: '2px', borderStyle: 'solid' } : {}
    };
  }

  onOver(link: Link, customStyleDirective: CustomStyleDirective) {
    this.hovering = link;
    if (link.customHover && link.hover.style) {
      customStyleDirective.setStyle(link.hover.style);
    }
  }

  onLeave(link: Link, customStyleDirective: CustomStyleDirective) {
    this.hovering = null;
    customStyleDirective.setStyle(link.style);
  }

  isActivePage(link: Link) {
    if (!link.pages || !link.pages.length || link.pages.includes('all')) {
      return true;
    }

    return link.pages.filter(x => this.router.isActive(x, false)).length > 0;
  }
  //filter to select the Categories by etablissement
  onFilterLinksByEtablissement() {
    return this.linkService.linkItems$.value?.filter(
      link => !link.isNotPublic || link.accueilsIds?.some(id =>
        this.accountVars?.etablissementAccueil?.some((accEtb) => accEtb.accueils.includes(id))
      ))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}

