import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { User, Role, ROLES_HIERARCHY, AccountType } from '@app/models/user';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, filter, map, take, tap, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import moment from 'moment';
import { BaseConfigService, SnackbarService, SessionService, PlatformService, UserService, PermissionService, FamilyService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthentificationConfig, OpenIdConfig, DoubleAuthConfig, oidcMessage, oidcMessageType, oidcVariablesForMessage } from '../models/authentification-config'
import { TotpVerifyComponent } from '@app/components/_elements/totp-verify/totp-verify.component'
import { HTTP_OPTIONS_JSON } from './api-crud.service'

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export type TotpAction = 'login' | 'resetTOTP' | 'resetPassword'

const storageKey = 'currentUser';
const storageAccountType = 'remember-account-type';

const EXPIRATION_CHECK_INTERVAL = 30;


/**
 * Authentication service.
 * @TODO: rename to UserService
 */
@Injectable()
export class AuthenticationService {

  private configUrl = 'conf/authentification';
  // private configSubject: BehaviorSubject<AuthentificationConfig>;
  // private config$: Observable<AuthentificationConfig>;

  private currentUserSubject: BehaviorSubject<User>;
  private storage: Storage;

  currentUser$: Observable<User>;
  currentUserChange$: Observable<User>;


  private accountTypeSubject = new BehaviorSubject<AccountType>(null);

  accountType$ = this.accountTypeSubject.asObservable();
  accountTypeChange$ = this.accountType$.pipe(distinctUntilChanged());

  private expirationChecker: Subscription;

  // OpenId Connect stuff
  creatingUserFromOIDC: any = null;
  private _oidcMessage = new BehaviorSubject<oidcMessage | null>(null)
  oidcMessage$: Observable<oidcMessage | null> = this._oidcMessage.asObservable()

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private baseConfigService: BaseConfigService,
    private platformService: PlatformService
  ) {

  }

  init() { // called from app.component
    this.storage = this.sessionService.dominoConnect ? sessionStorage : localStorage;
    const user = this.getUserFromStorage();
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUserChange$ = this.currentUser$.pipe(distinctUntilChanged((x, y) => x?.id === y?.id)); // A "real" user change is when the User.id changes
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(loginData: LoginContext) {

    this.storage = this.sessionService.dominoConnect || !loginData.remember ? sessionStorage : localStorage;

    return this.http.post<any>('authenticate', loginData).pipe(
      switchMap(result => {
        if (result.totpRequired) {
          return this.TotpInitVerify('login')
        } else {
          return of(result);
        }
      }),
      tap(user => {
        // login successful if token is provided
        if (user && user.token) {
          // Store user details (token, etc) to keep logged between pages
          this.updateUser(user as User);
        }
      })
    );
  }

  logout(redirectToLogin = true): Observable<any> {
    return this.http.get('/logout').pipe(
      tap(res => {
        if (!res || !res.oidcLogout || res.disconnected) {
          this.afterLogout(redirectToLogin)
        }
      })
    );
  }

  afterLogout(redirectToLogin = true) {
    console.warn('afterLogout')
    localStorage.setItem(storageAccountType, this.getAccountType());
    this.updateUser(null);
    this.sessionService.updateSession(null, true);
    // Maybe no need to be dependant / could be outside of observable call ?
    if (redirectToLogin) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  get isAuthenticated() {
    return !!this.currentUserValue;
  }

  private getUserFromStorage() {
    let userJSON = this.storage.getItem(storageKey);
    if (!userJSON && this.storage == localStorage) {
      userJSON = sessionStorage.getItem(storageKey);
    }
    let user = JSON.parse(userJSON)
    // console.log('getUserFromStorage / user:', user, 'this.storage:', this.storage)
    return user;
  }

  private storeUser(user?: User) {
    this.clearStoredUser();

    if (user) {
      this.storage.setItem(storageKey, JSON.stringify(user));
    }
  }

  private clearStoredUser() {
    sessionStorage.removeItem(storageKey);
    localStorage.removeItem(storageKey);
  }

  get role() {
    return this.currentUserValue ? this.currentUserValue.role : null;
  }

  updateUser(user: User, fromDomino = false, silent = false) {
    // For debug :
    if (user == null) console.warn('updateUser => null /  fromDomino:', fromDomino, ' / silent:', silent)

    if (fromDomino) {
      this.storage = sessionStorage;
    }

    this.storeUser(user);

    if (!silent) {
      this.currentUserSubject.next(user);
    }
  }

  hasRole(role: string) {
    return this.hasRoleChild(this.role, role);
  }

  private hasRoleChild(role: string, child: string) {
    if (role === child) {
      return true;
    }

    if (ROLES_HIERARCHY.hasOwnProperty(role)) {
      for (const innerRole of ROLES_HIERARCHY[role]) {
        // First check is just to ensure we don't have a Admin => [Admin] infinite loop
        if (innerRole !== role && this.hasRoleChild(innerRole, child)) {
          return true;
        }
      }
    }

    return false;
  }

  updateRole(role: string) {
    const userValue = this.currentUserValue;

    if (userValue && userValue.role !== role) {
      userValue.role = role;
      this.updateUser(userValue);
    }
  }

  updateExpiration(date: Date) {
    const userValue = this.currentUserValue;
    const actualSessionExpiration = (userValue && userValue.sessionExpiration) ? new Date(userValue.sessionExpiration) : null
    if (actualSessionExpiration != null && actualSessionExpiration < date) {
      userValue.sessionExpiration = date;
      this.updateUser(userValue);
    }
  }

  isUserExpired() {
    const expiration = this.currentUserValue?.sessionExpiration;

    // Normally this should take care of "TimeZone"
    return !expiration || moment().isAfter(expiration);
  }

  getAccountType() {
    return this.accountTypeSubject.value;
  }

  setAccountType(value: AccountType) {
    this.accountTypeSubject.next(value);

    const user = this.currentUserValue;

    if (user && user.accountType != value) {
      user.accountType = value;
      this.updateUser(user);
    }
  }

  storeIdAssmat(value: number) {
    const user = this.currentUserValue;

    if (user) {
      user.idAssmat = value;
      this.updateUser(user);
    }
  }

  storeIdEnseignant(value: number) {
    const user = this.currentUserValue;

    if (user) {
      user.idEnseignant = value;
      this.updateUser(user);
    }
  }

  updateEmail(user: User, newEmail: string) {
    return this.http.post<{ status: string }>(`users/update-email`, { id: user.id, newEmail });
  }

  sendUpdateEmailToken(token: string) {
    return this.http.post<{ status: string }>(`users/update-email-token`, { token });
  }

  // Unsubscribe previous session checker if there was any ...
  clearExpirationChecker() {
    if (this.expirationChecker) {
      this.expirationChecker.unsubscribe();
      this.expirationChecker = null;
    }
  }

  setExpirationChecker() {
    this.clearExpirationChecker();

    if (this.isAuthenticated) {
      // Check every 10 sec that user session isn't expired
      this.expirationChecker = timer(0, EXPIRATION_CHECK_INTERVAL * 1000).pipe(
        map(_ => this.isUserExpired()),
        filter(expired => expired),
        take(1)
      ).subscribe(expired => {
        // no need if user is already logged out
        if (this.isAuthenticated) {
          // autologout
          this.logout().subscribe(_ => {
            this.dialog.openDialogs.forEach(x => x.close());
            this.translate.get('login.autologout_message').subscribe(message => this.snackbar.error(message));
          });
        }
      });
    }
  }

  startExpirationChecker() {
    // only when user really changes (logs in/out), refresh "expirationChecker"
    this.currentUserChange$.subscribe(user => this.setExpirationChecker());
  }

  getConf(): Observable<AuthentificationConfig> {
    return this.baseConfigService.getFirstConf$('authentification').pipe(
      map(firstConf => {
        if (firstConf && firstConf.content) {
          return firstConf.content as AuthentificationConfig;
        } else {
          return null;
        }
      })
    )
  }

  getExternalAuthConf(): Observable<OpenIdConfig> {
    return this.getConf().pipe(
      map(conf => {
        if (conf && conf.externalAuth && conf.externalAuth.identityProvider !== '' && conf.externalAuth.isActive) {
          return conf.externalAuth
        } else {
          return null
        }
        // else return null
      })
    )
  }

  getDoubleAuthConf(): Observable<DoubleAuthConfig> {
    return this.getConf().pipe(
      map(conf => {
        if (conf && conf.doubleAuth && conf.doubleAuth.isActive) return conf.doubleAuth
        else return null
      })
    )
  }

  getConfForAdmin(): Observable<AuthentificationConfig> {
    return this.http.get<AuthentificationConfig>(this.configUrl)
  }

  saveConfig(conf: AuthentificationConfig): Observable<any> {
    return this.http.put(this.configUrl, conf, HTTP_OPTIONS_JSON);
  }

  redirectAfterLogin(user: User, route: ActivatedRouteSnapshot) {
    // Redirect to the originally wanted route, if any
    // Else if user role is defined, redirect to right home page
    // By default, redirect to home
    const params = route.queryParams
    const target = params.redirect ? params.redirect : (user.role ? (user.role === Role.Admin ? '/admin' : '/account') : '/');
    this.router.navigate([target], { replaceUrl: true });
  }


  // OIDC Features 

  openIdConnectInitLogin(from: 'login' | 'register') {
    return this.http.get<any>('oidc/init-authorize?from=' + from)
  }

  openIdConnectAuthorizeCallback(code, state) {
    const data = { code, state }
    return this.http.post<any>("oidc/authorize-callback", data).pipe(
      switchMap(result => {
        if (result.totpRequired) {
          return this.TotpInitVerify('login')
        } else {
          return of(result);
        }
      }),
      map(result => {

        if (!!result.error) {
          console.error('Erreur lors du retour OpenIdCallback.', result)
          throw (result.error)
        }
        // login successful if token is provided
        if (result && result.token) {
          if (!this.sessionService.dominoConnect) {
            this.storage = localStorage // forced to  localStorage to keep the connection            
          }
          // Store user details (token, etc) to keep logged between pages
          this.updateUser(result);
        } else if (result.userNotFound && result.userInfos && result.adulte) {
          this.creatingUserFromOIDC = result;
          sessionStorage.setItem('creatingUser', JSON.stringify(result)) // Pour conserver ça au cas où on raffraichirait la page avant de valider la création du user
        } else if (result.canceled) {
          console.log('TOTP Canceled')
        } else {
          console.error('Erreur lors du retour OpenIdCallback : token non trouvé.', result)
          throw {
            code: 'none',
            msg: `Un problème est survenu lors du retour du serveur d'authentification: aucun des paramètres attendus n'a été trouvé`,
            detail: result
          }
        }

        return result;
      })
    );
  }

  openIdConnectLogoutCallback(state) {
    return this.http.get<any>("oidc/logout-callback/" + state).pipe(
      tap(res => {
        if (res.disconnected) {
          this.afterLogout(false)
        }
      })
    );
  }

  setOidcMessage(msg: oidcMessage): void {
    this._oidcMessage.next(msg);
  }

  // TOTP Features

  TotpGetKey(password): Observable<any> {
    return this.http.post('/totp/get-key', { password })
  }

  TotpActivate(activationCode: string): Observable<any> {
    return this.http.post('/totp/activate', { activationCode })
  }


  TotpInitVerify(action: TotpAction): Observable<any> {
    console.log("TotpInitVerify")
    // Display Dialog with TotpVerifyComponent, then when user valid the form, TotpVerify() is called,
    //  and the return will be send in the close event of the dialog


    let dialogRef = this.dialog.open(TotpVerifyComponent, {
      disableClose: true,
      ariaLabel: '2FA Code verification',
      ariaModal: true,
      maxWidth: 500,
      data: { action }
    })

    this.platformService.adaptDialogToScreen(dialogRef);

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result.success && result.user) {
          return result.user
        } else {
          result.canceled = true
          return result
        }
      })
    )
  }

  TotpVerify(totpCode: string, action: TotpAction): Observable<any> {
    return this.http.post<any>('/totp/verify', { totpCode, action })
  }

}
