<app-dialog-header title="{{ date|date:'EEEE dd MMMM y'|titlecase }}"></app-dialog-header>

<div mat-dialog-content class="thin-scrollbar">

  <div *ngIf="editable" class="errors-list fx-direction-col" style="padding: 10px 0 0 0;">

    <ng-container *ngIf="!isOutsidePeriode">

      <mat-error *ngIf="timeError.length !== 0">
        <mat-icon color="warn">error</mat-icon>
        <span>Horaires invalides. L'heure de fin doit être supérieure à l'heure de début.</span>
      </mat-error>

      <mat-error *ngIf="conflictPresence.length !== 0">
        <mat-icon color="warn">error</mat-icon>
        <span>Votre sélection chevauche un pointage existant
          ({{conflictPresence[0].plage1Deb}}-{{conflictPresence[0].plage1Fin}}).</span>
      </mat-error>

      <mat-error *ngIf="conflictTimes.length !== 0">
        <mat-icon color="warn">error</mat-icon>
        <span>Les plages horaires ne peuvent pas se chevaucher.</span>
      </mat-error>

      <mat-error *ngIf="isInvoiced">
        <mat-icon color="warn">error</mat-icon>
        <span>Le pointage à déjà été facturé.</span>
      </mat-error>

    </ng-container>

    <mat-error *ngIf="isOutsidePeriode">
      <mat-icon color="warn">error</mat-icon>
      <span>La date sélectionnée est en dehors de la période d'inscription de l'enfant (du {{debutInscriptionDisplay}}
        au {{finInscriptionDisplay}}).</span>
    </mat-error>

  </div>

  <h2 *ngIf="editable" style="text-align: center;">
    {{ existing && editable ? 'Modification Pointage' : !editable ? 'Pointage' :'Nouveau(x) Pointage(s)' }}
  </h2>

  <div *ngFor="let data of selectData; let i = index">

    <mat-radio-group [(ngModel)]="data.type" (ngModelChange)="checkUpdatedData()" class="presence-absence-choice">
      <mat-radio-button [disabled]="!editable || isInvoiced" value="P">Présence</mat-radio-button>
      <mat-radio-button [disabled]="!editable || isInvoiced" value="A">Absence</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="absenceRubriqueChoices?.length && data.type === 'A'" style="width:100%; margin-bottom: 14px;"
      class="no-hint">
      <mat-label>Type d'absence</mat-label>
      <mat-select [(ngModel)]="data.idRubriquePlanning" (ngModelChange)="checkUpdatedData()">
        <mat-option *ngFor="let yRub of absenceRubriqueChoices" [value]="yRub.id">
          {{ yRub.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="fx-direction-row fx-around-center">

      <div></div>

      <div>
        <div *ngIf="!planningConfig.hideMealChoice" class="meal-choices">
          <mat-checkbox labelPosition="before" [disabled]="!editable || isInvoiced" [(ngModel)]="data.petitDej"
            (ngModelChange)="checkUpdatedData()" matTooltip='Petit déjeuner'>P</mat-checkbox>
          <mat-checkbox labelPosition="before" [disabled]="!editable || isInvoiced" [(ngModel)]="data.collation"
            (ngModelChange)="checkUpdatedData()" matTooltip='Collation'>C</mat-checkbox>
          <mat-checkbox labelPosition="before" [disabled]="!editable || isInvoiced" [(ngModel)]="data.repasMidi"
            (ngModelChange)="checkUpdatedData()" matTooltip='Repas Midi'>M</mat-checkbox>
          <mat-checkbox labelPosition="before" [disabled]="!editable || isInvoiced" [(ngModel)]="data.gouter"
            (ngModelChange)="checkUpdatedData()" matTooltip='Gouter'>G</mat-checkbox>
          <mat-checkbox labelPosition="before" [disabled]="!editable || isInvoiced" [(ngModel)]="data.repasSoir"
            (ngModelChange)="checkUpdatedData()" matTooltip='Repas Soir'>S</mat-checkbox>
        </div>

        <div class="time-input" [ngClass]="{'noPicker': !editable || isInvoiced}">
          <label>De</label>
          <app-time-input [disabled]="!editable || isInvoiced" [(ngModel)]="data.plage1Deb"
            (ngModelChange)="checkUpdatedData()" [picker]="!(!editable || isInvoiced)"
            (ngModelChange)="checkConflicts()">
          </app-time-input>
        </div>

        <div class="time-input" [ngClass]="{'noPicker': !editable || isInvoiced}">
          <label>à</label>
          <app-time-input [disabled]="!editable || isInvoiced" [(ngModel)]="data.plage1Fin"
            (ngModelChange)="checkUpdatedData()" [picker]="!(!editable || isInvoiced)"
            (ngModelChange)="checkConflicts()">
          </app-time-input>
        </div>
        <mat-divider *ngIf="!existing"></mat-divider>
      </div>

      <div>
        <button mat-mini-fab class="delete-button" color="warn" *ngIf="selectData.length > 1 && !existing"
          (click)="onDeletePointage(i); checkConflicts()" matTooltip="Supprimer le pointage">
          <mat-icon class="icon-delete">delete_forever</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <div *ngIf="!existing" class="add-button-container">
    <button mat-mini-fab class="add-button" color="primary" matTooltip="Ajouter un pointage"
      (click)="addPointage(type); checkConflicts()">
      <mat-icon class="icon-add">add</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-actions *ngIf="editable">
  <button mat-raised-button color="warn" *ngIf="existing" [disabled]="isInvoiced"
    (click)="onDelete()">Supprimer</button>
  <button mat-raised-button color="primary"
    [disabled]="timeError.length !== 0 || conflictPresence.length !== 0 || conflictTimes.length !== 0 || isOutsidePeriode  || isInvoiced || (existing ? !isUpdated : false)"
    (click)="validate()">
    Valider
  </button>
</div>