import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { PermissionService } from '@app/services/permission.service';
import { PlatformService } from '@app/services/platform.service';
import { Router } from '@angular/router';
import { BaseConfigService, FamilyService, FormConfigService } from '@app/services';
import { filter, takeUntil, tap, switchMap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PreInscription } from '@app/models/pre-inscription';
import { BoardCard } from '@app/models/board-card';

@Component({
  selector: 'app-user-preinscriptions',
  templateUrl: './preinscription-list.component.html',
  styleUrls: ['./preinscription-list.component.scss']
})
export class PreinscriptionListComponent implements OnInit, OnDestroy {

  preinscriptions: PreInscription[];

  formParts: any[];
  readOnly: boolean;
  permToCreate: boolean;

  loading = false;
  addBtnUrl: string;
  onDestroy$ = new Subject();

  constructor(
    private familyService: FamilyService,
    public platformService: PlatformService,
    public permService: PermissionService,
    private formConfigService: FormConfigService,
    public router: Router,
    private baseConfigService: BaseConfigService
  ) { }

  ngOnInit() {
    this.readOnly = !this.permService.hasPermission('preinscription_edit') || !this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('preinscription_create') && this.familyService.currentFamily.active;
    this.loadFormSteps().subscribe(stepNames => this.formParts = stepNames);

    this.baseConfigService.getFirstConf$("board-user")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conf => {
        const cards = conf.content.cards;
        const idDemarche = cards.find((card: BoardCard) => card.component === 'preinscription').addBtnDemarcheId;
        this.addBtnUrl = idDemarche ? `add/${idDemarche}` : 'add';
      })

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.preinscriptions = null; this.loading = false; }),
      switchMap(f => this.familyService.getFamilyPreInscriptions(f, "Mikado")
        .pipe(tap((data: PreInscription[]) => this.preinscriptions = data)))
    ).subscribe();
  }

  loadFormSteps() {
    const filteredSteps = ['InformationsImportantes']; // steps excluded in "read / edit" (not create) modes
    return this.formConfigService.getForm('form-pre-inscription').pipe(
      map(steps => steps
        .filter(step => (!filteredSteps.includes(step.stepName)))
        .map(step => ({ stepName: step.stepName, label: step.label }))
      )
    );
  }

  openForm(PreInscriptionId, stepName) {
    this.router.navigate([`/account/preinscription/edit/${PreInscriptionId}/${stepName}`]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
