import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WaitingRoomComponent } from '@app/components/_user/reservation/waiting-room/waiting-room.component';
import { WaitingRoomErrorComponent } from '@app/components/_user/reservation/waiting-room/error/waiting-room-error.component';
import { PlatformService } from './platform.service';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, interval, Subject, timer, throwError, of, fromEvent, from } from 'rxjs';
import { audit, concatMap, delayWhen, filter, finalize, map, mergeMap, retryWhen, shareReplay, switchMap, takeUntil, takeWhile, tap, throttleTime } from 'rxjs/operators';
import { WaitingRoomConfirmComponent } from '@app/components/_user/reservation/waiting-room/confirm/waiting-room-confirm.component';
import { BaseConfigService } from './config/base.service';
import { ReservationConfig, WaitingRoomConfig } from '@app/models/reservation';
import { AuthenticationService } from './authentication.service';


export enum WaitingRoomStatus {
  noWaitingRoom,
  mustWait,
  canGo,
  error
}

export interface WaitingRoomData {
  status: WaitingRoomStatus;
  rank: number;
  serverTime: Date;
  timeDiffWithSrv: number; // in millisecond
  refreshInterval: number;
  lastRefresh: Date;
  errorMessage?: string;
  accessTime?: Date;
  limitTime?: Date;
  maxExtend?: number
  extensionsCount?: number;
  extensionsLeft?: number;
  waitingTimeMin?: number; // in ms
  waitingTimeMax?: number; // in ms
}

export interface WaitingRoomHeartbeatResult {
  ok: boolean,
  status: string,
  message: string,
  stop?: boolean
}

export interface WaitingRoomExtendResultData {
  message?: string;
  error?: string;
  extendedAt?: Date;
  extendedFor?: number;
  newLimit?: Date;
  extensionsCount?: number;
  extensionsLeft?: number;
}

export type ErrorType = 'errorEnterRoom' | 'expiredByServer' | 'timeIsUp' | 'other'
export type ConfirmType = 'quitWaitingRoom' | 'quitRestrictedRoom' | 'stillHere'


@Injectable({
  providedIn: 'root'
})
export class WaitingRoomService implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  // configUrl = 'conf/waiting-room';
  private baseUrl = 'reservations/waiting-room'

  data$ = new BehaviorSubject<WaitingRoomData>(null)

  inWaitingRoom$ = new BehaviorSubject<boolean>(false);
  inRestrictedRoom$ = new BehaviorSubject<boolean>(false);
  waitingForRequest$ = new BehaviorSubject<boolean>(false);
  countDown$ = new Subject<Date>();
  timeIsUp$ = new Subject();
  isQuitting = false;
  isSmall: boolean;

  private waitingRoomDialogRef: MatDialogRef<WaitingRoomComponent>;
  private confirmDialogRef: MatDialogRef<WaitingRoomConfirmComponent>;


  constructor(
    private http: HttpClient,
    private configService: BaseConfigService,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.platformService.isSmall$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => this.isSmall = value);
  }


  get config$(): Observable<WaitingRoomConfig> {
    // NB : cache already managed in configService
    return this.configService.get<ReservationConfig>('reservation').pipe(
      map(resaConf => resaConf.waitingRoom)
    )
  }

  setData(data: WaitingRoomData) {
    this.data$.next(data)
    if (this.inRestrictedRoom$.value && data.status !== WaitingRoomStatus['canGo']) {
      this.inRestrictedRoom$.next(false)
    }
    // if (this.inWaitingRoom$.value && data.status !== WaitingRoomStatus['mustWait']) {
    //   this.inWaitingRoom$.next(false)
    // }

  }

  openWaitingRoom() {
    if (this.waitingRoomDialogRef) return; // already openned

    this.inWaitingRoom$.next(true);
    this.waitingRoomDialogRef = this.dialog.open(WaitingRoomComponent, {
      minWidth: this.isSmall ? '100vw' : '50vw',
      maxWidth: this.isSmall ? '100vw' : '75vw',
      disableClose: true,
      ariaLabel: 'Salle d\'attente',
      closeOnNavigation: false
    });

    this.platformService.adaptDialogToScreen(this.waitingRoomDialogRef);

    this.waitingRoomDialogRef.afterClosed().subscribe(_ => {
      console.warn('waitingRoomDialogRef.afterClosed', _)
      this.waitingRoomDialogRef = null

    })
  }

  closeWaitingRoom() {
    if (!!this.confirmDialogRef) this.confirmDialogRef.close()
    if (!!this.waitingRoomDialogRef) this.waitingRoomDialogRef.close()
  }


  enterRestrictedRoom() {
    console.warn('enterRestrictedRoom')
    this.closeWaitingRoom()
    this.initCountDownAndHeartbeat()
    this.inWaitingRoom$.next(false);
    this.inRestrictedRoom$.next(true);
    this.launchIdleDetector()
  }


  launchIdleDetector() {
    let lastActiveDate: Date = new Date()
    let dialogOpened = false;
    const maxIdleTime = 30 * 1000 // in ms

    // Record user activity
    from(['keydown', 'click', 'wheel', 'mousemove', 'touchstart']).pipe(
      mergeMap((eventName: string) => fromEvent(document, eventName)),
      takeUntil(this.inRestrictedRoom$.pipe(filter(v => !v))),
      filter(_ => !dialogOpened),
      throttleTime(500),
    ).subscribe(_ => lastActiveDate = new Date());

    // Timer to trigger action when user is INactive (after maxIdleTime)
    interval(1000).pipe(
      takeUntil(this.inRestrictedRoom$.pipe(filter(v => !v))),
      filter(_ => !dialogOpened),
    ).subscribe(_ => {
      const now = new Date()
      const inactiveSince = now.getTime() - lastActiveDate.getTime()
      if (inactiveSince > maxIdleTime && !this.waitingForRequest$.value) {
        console.warn('You are inactive since ', lastActiveDate, 'that represent ', inactiveSince, 'ms')
        this.confirm('stillHere').subscribe(confirm => {
          lastActiveDate = new Date()
          setTimeout(() => { dialogOpened = false; });
          if (!confirm) {
            this.quitWaitingRoom('L\'utilisateur n\'est plus devant son écran', true, true)
          }
        })
        dialogOpened = true;
      }
    })
  }

  confirm(type: ConfirmType) {
    if (!!this.confirmDialogRef) {
      this.confirmDialogRef.close()
    }
    this.confirmDialogRef = this.dialog.open(WaitingRoomConfirmComponent, {
      data: {
        type,
        minWidth: this.isSmall ? '100vw' : '25vw',
        maxWidth: this.isSmall ? '100vw' : '50vw',
        disableClose: true,
      }
    })

    this.platformService.adaptDialogToScreen(this.confirmDialogRef);

    return this.confirmDialogRef.afterClosed().pipe(
      tap(confirmed => {
        console.log('confirmed', confirmed)
        this.confirmDialogRef = null;
      })
    )
  }

  displayError(type: ErrorType, errorMessage?: string) {
    // an error can occur when waiting room is full (over max quota),
    // so we can't access the ressource, but can't enter in waiting-room
    // => We display a message, and go back when user close it
    const dialogRef = this.dialog.open(WaitingRoomErrorComponent, {
      minWidth: this.isSmall ? '100vw' : '50vw',
      maxWidth: this.isSmall ? '100vw' : '75vw',
      disableClose: true,
      ariaLabel: 'Erreur Salle d\'attente',
      data: { type, errorMessage }
    })

    this.platformService.adaptDialogToScreen(dialogRef);

    this.closeWaitingRoom()

  }

  // When user cancel while he is in waiting room or in the restricted room
  quitWaitingRoom(message, abort: boolean, redirectToHome: boolean) {
    this.isQuitting = true;
    this.http.post(this.baseUrl + '/cancel-registration', { message, abort }).subscribe(res => {
      this.inWaitingRoom$.next(false);
      this.inRestrictedRoom$.next(false);

      if (this.waitingRoomDialogRef) {
        this.waitingRoomDialogRef.close()
      }

      if (redirectToHome) this.router.navigate(['/account'])
      setTimeout(() => { this.isQuitting = false; });
    })
  }


  initCountDownAndHeartbeat() {

    // When in "restricted room", send an heartbeat to keep registration "online" every "refreshInterval"
    this.inRestrictedRoom$.pipe(
      filter(val => !!val),
      // tap(_ => console.warn('we are in ')),
      switchMap(_ => interval(1000)), // emettre toutes les secondes pour alimenter le 'countdown'
      takeWhile(_ => this.inRestrictedRoom$.value),
      takeUntil(this.timeIsUp$),
      // Manage a countdown (will display time left in the WaitingRoomNotifierComponent)
      tap(_ => {
        let countDown = new Date();
        const limitTime = this.data$.value.limitTime
        // Diff between limit and now => give us milliseconds, and put it in a date objext so we have a 'Date' 
        countDown.setTime(Math.max(limitTime.getTime() - countDown.getTime(), 0))
        // Adjust to UTC because 'Date' take care of timeZone, and we only want a duration not a date
        countDown.setTime(countDown.getTime() + (countDown.getTimezoneOffset() * 60 * 1000))
        this.countDown$.next(countDown);

        if (countDown.getTime() <= 0 && !this.waitingForRequest$.value) {
          setTimeout(() => {
            this.timeIsUp$.next()
            this.timeIsUp$.complete()
          }, 1000)
        }
      }),
      // maintenant on bascule sur le 'refreshInterval' pour envoyer le heartbeat
      audit(_ => timer(this.data$.value.refreshInterval - 1000)), // '-1000' is because we already have 1000 ms delay with the 'interval' above
      takeWhile(_ => !this.isQuitting),
      takeUntil(this.timeIsUp$),
      switchMap(_ => this.sendHeartbeat())
    ).subscribe(heartbeatRes => {
      if (heartbeatRes.stop) { // waiting room must have been deactivate , so we can cancel
        this.inRestrictedRoom$.next(false)
      } else if (!heartbeatRes.ok) {
        if (!this.isQuitting) {
          heartbeatRes.message ? this.displayError('expiredByServer', heartbeatRes.message) : null
          this.inRestrictedRoom$.next(false)
          this.router.navigate(['/account'])
        }
      }

    })

    this.timeIsUp$.subscribe(() => {
      if (!this.isQuitting) {
        this.displayError('timeIsUp')
        this.quitWaitingRoom('Le temps est écoulé', false, true)
      }
    })

  }

  // When User is in Restricted room, we must send heartbeat to the server regularly
  private heartbeat$: Observable<WaitingRoomHeartbeatResult>
  sendHeartbeat(): Observable<WaitingRoomHeartbeatResult> {


    if (!this.heartbeat$) {
      const maxRetry = 10;
      const cacheTimeout = (this.data$.value.refreshInterval / 2) || 5000

      this.heartbeat$ = this.http.get<WaitingRoomHeartbeatResult>('/reservations/waiting-room/heartbeat')
        .pipe(
          retryWhen(errors =>
            errors.pipe( // au cas où on aurait une erreur, on retente car sans ça l'utilisateur sera retiré de la file d'attente
              concatMap((err, count) => {
                if (count < maxRetry) {
                  console.warn('Erreur lors de l\'envoi du heartbeat... retry n°' + count, err)
                  return of(err);
                }
                return throwError(err);
              }),
              delayWhen(_ => timer(Math.floor(this.data$.value.refreshInterval / 2)))
            )),

          // Here we want to prevent multiple request at the same time
          // This worked, but seem it's based on a bug of RXJS and it will be deprecated in V7 (see https://github.com/ReactiveX/rxjs/issues/6260)
          // publishReplay(1, 5000),
          // refCount(),
          // take(1),

          // So here is my dirty solution : use shareReplay + destroy manually the heartbeat$ observable after timeOut
          // but caution because finalize() must not be shared, so it must be placed before the shareReplay()
          finalize(() => setTimeout(() => { this.heartbeat$ = null }, cacheTimeout)),
          shareReplay(1, cacheTimeout),
        )
    }

    return this.heartbeat$

  }




  private extendLimit$: Observable<WaitingRoomExtendResultData>
  extendLimit(): Observable<WaitingRoomExtendResultData> {

    console.log('extendLimit', this.inRestrictedRoom$.value)
    const cacheTimeout = (this.data$.value.refreshInterval / 2) || 5000

    if (!this.inRestrictedRoom$.value) {
      return of({ error: 'notInRestrictedRoom' })

    } else if (this.data$.value.extensionsLeft <= 0) {
      return of({ error: 'noExtensionsLeft' })

    } else {
      console.log('this.extendLimit$', this.extendLimit$)
      if (!this.extendLimit$) {
        this.extendLimit$ = this.http.post<WaitingRoomExtendResultData>('/reservations/waiting-room/extend-limit', {}).pipe(
          tap(res => {
            console.log('extendLimit res', res)
            const data = this.data$.value
            data.extensionsCount = res.extensionsCount;
            data.extensionsLeft = res.extensionsLeft;
            this.setData(data)
          }),
          finalize(() => setTimeout(() => { this.heartbeat$ = null }, cacheTimeout)),
          shareReplay({ bufferSize: 1, windowTime: cacheTimeout, refCount: false })
        )
      }
      return this.extendLimit$
    }

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // onBeforeUnload(event): void {
  //   // Here would like to quit the waiting room when the user close the navigator, 
  //   // but is it a good idea !?? What if user reload the page !??
  //   // => so i comment that for the moment (not tested)

  //   if (this.inRestrictedRoom$.value || this.inWaitingRoom$.value) {

  //     // As standard http.post will not be sent while the page is unloading, we would like
  //     // to use navigator.sendBeacon(), but it does not allow to define httpHeader, so we can't send our Authorization Token
  //     // Instead, we could use the 'fetch()' method with 'keepalive' option
  //     // See : https://developer.mozilla.org/fr/docs/Web/API/fetch#keepalive

  //     const currentUser = this.authenticationService.currentUserValue;
  //     const data = { message: 'Fermeture du navigateur', abort: true }

  //     fetch(this.baseUrl + '/cancel-registration', {
  //       keepalive: true,
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': currentUser.token,
  //       },
  //       body: JSON.stringify(data),
  //     });
  //   }
  // }

  // attachOnBeforeUnload() {
  //   document.body.addEventListener("beforeunload", this.onBeforeUnload);
  // }


}
