import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnneeScolaire, CogitoEcole, CogitoInscPreIsnc, CogitoSecteurScolaire, ConfigCogito, MotifDerogation, NiveauScolaire } from '@app/models/cogito';
import { Usager, UsagerChild } from '@app/models/consumer';
import { DemarcheFormReponse } from '@app/models/demarche';
import { FamilyService, PlatformService, SnackbarService } from '@app/services';
import { CogitoService } from '@app/services/cogito.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-cogito-preinscription',
  templateUrl: './edit-cogito-preinscription.component.html',
  styleUrls: ['./edit-cogito-preinscription.component.scss']
})
export class EditCogitoPreinscriptionComponent implements OnInit, OnDestroy {

  idFromUrl: number;
  config: ConfigCogito;
  options: { childList: UsagerChild[], secteursScolaireList: CogitoSecteurScolaire[], secteursScolaireListBydefault: CogitoSecteurScolaire[] };
  isEdit: boolean = false;
  saving: boolean = false;
  preinscription: CogitoInscPreIsnc;
  selectedChild: UsagerChild;
  selectedAnneeScolaire: AnneeScolaire;
  selectedNiveauScolaire: NiveauScolaire;
  selectedSecteurScolaire: CogitoSecteurScolaire;
  listNiveauxScolairesAvailable: NiveauScolaire[];
  ageEnfantCalculateByAnneeScolaire: { years: number, months: number };
  dateToCalculate: Date;
  listEcolesAvailable: CogitoEcole[];
  listSecteursScolaireAvailable: CogitoSecteurScolaire[];
  motifDerogationScolaire: MotifDerogation[];
  demandeDerogation: boolean = false;
  libelleSecteurScolaireOrigine: string = "";
  preInscriptionsActive: boolean;
  isMobile: boolean;
  private onDestroy$ = new Subject<void>();

  @Input() fromDemarche: boolean;
  @Input() usager: Usager;
  @Output() save = new EventEmitter<DemarcheFormReponse>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private cogitoService: CogitoService,
    public platformService: PlatformService,
    private translate: TranslateService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {

    this.platformService.isMobile$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => this.isMobile = value);

    if (!this.fromDemarche) {
      this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    }

    this.loadData().subscribe(_ => {
      if (this.isEdit) {

        if (this.preinscription.derogationExist) {
          this.demandeDerogation = true;
          const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.preinscription.cycleScolaireName, this.options);
          this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
        }

        this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.preinscription.anneeScolaire.dateDebut);
        this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.preinscription.dateNaissance);

        this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
          this.preinscription.cycleScolaireName,
          this.options, this.config,
          this.demandeDerogation,
          this.preinscription)

        this.listEcolesAvailable = this.cogitoService.globalFilterEcoles(
          this.preinscription.secteurScolaire,
          this.preinscription.cycleScolaireName,
          this.options,
          this.config,
          this.demandeDerogation,
          this.preinscription)

        this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);
        this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.preinscription, this.dateToCalculate);

        if (!this.config.enabledSelectNiveauScolaire) {
          this.listNiveauxScolairesAvailable = [...this.config.listNiveauxScolaires];
        }
      } else {
        this.preinscription = {};
        if (!this.config.enabledSelectAnneeScolaire) {

          this.preinscription.anneeScolaire = this.config.anneeScolaireByDefault;
          this.config.listAnneesScolaires = [];
          this.config.listAnneesScolaires.push(this.config.anneeScolaireByDefault)
          this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.preinscription.anneeScolaire.dateDebut)

          if (this.options.childList) {
            this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.preinscription, this.dateToCalculate);
          }
        }

        if (this.fromDemarche && this.usager && this.options?.childList) {
          this.selectedChild = this.options.childList.find(child => child.id === this.usager.id);
          this.childChanged(this.selectedChild.id);
        }
      }
    })
  }

  loadData() {
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        const loaders = [];

        loaders.push(this.cogitoService.getConfigForUser('cogitoPreinscription').pipe(tap((c: any) => {
          this.config = c.config;
          this.preInscriptionsActive = this.cogitoService.checkPermInscription(this.config);
        })));

        if (this.idFromUrl) {
          this.isEdit = true;

          // load edit preinscription
          loaders.push(this.cogitoService.getPreinscription(family, this.idFromUrl).pipe(tap(p => {
            this.preinscription = p;
          })));
        }

        loaders.push(this.cogitoService.getOptions(family, 'cogitoPreinscription').pipe(tap(o => {
          this.options = o;
        })));


        return forkJoin(loaders);
      })
    );
  }

  compareAnneeFn(annee1: AnneeScolaire, annee2: AnneeScolaire) {
    return annee1 && annee2 ? annee1.idAnneeScolaire === annee2.idAnneeScolaire : annee1 === annee2;
  }

  childChanged(idChild: number) {
    this.selectedChild = this.options.childList.find(child => child.id === idChild);
    this.preinscription.idEnfant = this.selectedChild.id;
    if (this.selectedAnneeScolaire || this.preinscription.anneeScolaire) {

      this.selectedAnneeScolaire = this.selectedAnneeScolaire ? this.selectedAnneeScolaire : this.preinscription.anneeScolaire;
      this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
      this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);

      if (!this.config.enabledSelectNiveauScolaire) {
        this.preinscription.niveauScolaire = this.listNiveauxScolairesAvailable.length ? this.listNiveauxScolairesAvailable[0].idNiveauScolaire : 0;
        this.niveauScolaireChanged(this.preinscription?.niveauScolaire)
      }

      if (!this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.preinscription.niveauScolaire)) {
        this.preinscription.niveauScolaire = null;
        this.niveauScolaireChanged(null);
      }
    }
  }

  anneeScolaireChanged(anneeScolaire: AnneeScolaire) {
    this.selectedAnneeScolaire = this.config.listAnneesScolaires.find(annee => annee.idAnneeScolaire === anneeScolaire.idAnneeScolaire);
    this.preinscription.anneeScolaire = this.selectedAnneeScolaire;

    this.dateToCalculate = this.cogitoService.setAnneeScolaireToCalculate(this.selectedAnneeScolaire.dateDebut);
    this.ageEnfantCalculateByAnneeScolaire = this.cogitoService.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
    this.listNiveauxScolairesAvailable = this.cogitoService.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years, this.config);

    if (!this.config.enabledSelectNiveauScolaire) {

      this.preinscription.niveauScolaire = this.listNiveauxScolairesAvailable[0]?.idNiveauScolaire;
      this.niveauScolaireChanged(this.preinscription.niveauScolaire)

    } else if (this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.preinscription.niveauScolaire)) {

      this.niveauScolaireChanged(this.preinscription.niveauScolaire)

    } else {

      this.preinscription.niveauScolaire = null;
      this.niveauScolaireChanged(null);

    }

    if (this.options.childList) {
      this.cogitoService.setAgeEnfantDisplayByAnneeScolaire(this.options, this.isEdit, this.preinscription, this.dateToCalculate);
    }

  }

  niveauScolaireChanged(idNiveauScolaire: Number) {
    this.selectedNiveauScolaire = this.config.listNiveauxScolaires.find(niveau => niveau.idNiveauScolaire === idNiveauScolaire);

    this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
      this.selectedNiveauScolaire?.cycleScolaire,
      this.options,
      this.config,
      this.demandeDerogation,
      this.preinscription);

    if (this.demandeDerogation) {
      const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.selectedNiveauScolaire?.cycleScolaire, this.options);
      this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
    }

    if (this.config.enabledDerogationObligatoire && !this.demandeDerogation) {
      this.preinscription.secteurScolaire = this.listSecteursScolaireAvailable[0]?.idSecteurScolaire;
      this.secteurScolaireChanged(this.preinscription.secteurScolaire);

    } else if (this.listSecteursScolaireAvailable.find(secteur => secteur.idSecteurScolaire === this.preinscription.secteurScolaire)) {

      this.secteurScolaireChanged(this.preinscription.secteurScolaire);

    } else {

      this.preinscription.secteurScolaire = null;
      this.secteurScolaireChanged(null);

    }
  }

  secteurScolaireChanged(idSecteurScolaire: number) {
    this.listEcolesAvailable = this.cogitoService.globalFilterEcoles(
      idSecteurScolaire,
      this.selectedNiveauScolaire?.cycleScolaire || this.preinscription.cycleScolaireName,
      this.options,
      this.config,
      this.demandeDerogation,
      this.preinscription);

    if (!this.listEcolesAvailable.find(ecole => ecole.idEcole === this.preinscription.ecole)) {
      this.preinscription.ecole = null;
    }
  }

  setDisplayDateToCalculate(dateToCalculate: Date): string {
    return moment(dateToCalculate).format('DD/MM/YYYY');
  }

  onClickDemandeDerogation() {
    this.demandeDerogation = !this.demandeDerogation;

    this.listSecteursScolaireAvailable = this.cogitoService.globalfilterSecteursScolaires(
      this.selectedNiveauScolaire?.cycleScolaire,
      this.options, this.config,
      this.demandeDerogation,
      this.preinscription);

    if (!this.demandeDerogation) {
      this.preinscription.idMotifDerogation = null;
      this.preinscription.secteurScolaire = this.listSecteursScolaireAvailable[0]?.idSecteurScolaire;
      this.secteurScolaireChanged(this.preinscription.secteurScolaire);
    } else {
      const secteurScolaireOrigine = this.cogitoService.getSecteurScolaireOrigine(this.selectedNiveauScolaire?.cycleScolaire, this.options);
      this.libelleSecteurScolaireOrigine = secteurScolaireOrigine ? secteurScolaireOrigine.libelle : "";
      this.preinscription.secteurScolaire = null;
      this.secteurScolaireChanged(null);
    }

  }

  onClickValidatePreInscription() {
    let preinscription = this.mapToSave(this.preinscription);
    const family = this.familyService.currentFamily;

    this.saving = true;

    this.cogitoService.savePreinscription(preinscription, family?.id).pipe(
      finalize(() => this.saving = false)
    ).subscribe((res: any) => {
      if (!res.errors) {
        if (this.fromDemarche) {
          let inscriptionData = { id: res.id, type: 'Preinscription', usager: this.usager };
          this.save.emit(inscriptionData);
        } else {
          this.router.navigate(['/account/cogito-preinscriptions']);
        }
        this.snackbar.info('info.success.saving');
      } else {
        res.errors.forEach((err: { message: string }) => {
          let message = err.message as string;

          if (message === 'cogito.error.doublon_preinscription_exist' ||
            message === 'cogito.error.inscriptionOnSameAnnee' ||
            message === 'cogito.error.inscriptionOnSameAnneeAndSchool') {
            message = this.translate.instant(message);
            this.snackbar.error(message);
          }
        })
      }
    });
  }

  onClickAnnuler() {
    history.back();
  }

  mapToSave(data: CogitoInscPreIsnc): any {

    let dataToSave: any = {};
    dataToSave._idEnfant = data.idEnfant;
    dataToSave._idAnneeScolaire = data.anneeScolaire.idAnneeScolaire;
    dataToSave._idNiveauScolaire = data.niveauScolaire;
    dataToSave._idSecteurScolaire = data.secteurScolaire;
    dataToSave._idEcole = data.ecole;
    dataToSave.idPreInscriptionScolaire = data.idPreInscriptionScolaire;
    dataToSave.idMotifDerogation = data.idMotifDerogation;
    dataToSave.commentaire = data.commentaire
    dataToSave.derogationExist = this.demandeDerogation ? true : false;

    return dataToSave;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
